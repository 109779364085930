import {Form, Loader, MessageType} from 'components'
import {
  constructSubmitJsPayload,
  convertDataBagFastActionDataToContributionFormValues,
  convertDataBagProfileDataToContributionFormValues,
  convertSignupIdentityFieldsToContactInformation,
  filterNotRequiredAddressFields,
  getIdentityFieldsFromContributionFormValues,
  submitPaymentAndSignup,
} from './util'
import fundraisingFormReducer, {ActionType} from './FundraisingFormReducer'
import {
  getFastActionDataBag,
  getFastActionIdentity,
  getFormDefinition,
  getProfileDataBag,
} from './api'
import {isEmpty, logError} from 'util/common'
import {setCurrentVolunteer, setEventSignups} from 'redux/actions'
import {
  setFormDefinition,
  setFormValues,
  setModalState,
  setSignupSucceeded,
  setSignupValues,
  setSkippingDonationState,
  setSubmitJsForm,
} from 'events/details/FundraisingForm/actions'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useReducer, useState} from 'react'

import Card from 'components/Card'
import EventClosedBox from 'events/components/EventClosedBox'
import {F} from 'util/i18n'
import FundraisingFormFields from './FundraisingFormFields'
import Message from 'components/Message'
import {OnlineActionFillSource} from 'app/enums'
import PostModals from '../SignupForm/PostModals'
import Sticky from '../SignupForm/Sticky'
import flag from 'experiments/flag'
import {getEventTypeAffinityChecks} from 'util/event'
import {initializeSubmitJs} from 'vendor/ea-submit'
import useIdentityFields from 'hooks/identity-fields'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router-dom'

function FundraisingFormHeader({organization, event, isSkippingDonation}) {
  const {isPromptForDonation} = getEventTypeAffinityChecks(event)
  const isPromoted = organization.id !== event.organization.id

  if (isSkippingDonation) {
    if (isPromoted) {
      return (
        <F
          defaultMessage="Sign up now with {eventOrganizationName}"
          values={{eventOrganizationName: event.organization.name}}
        />
      )
    }
    return <F defaultMessage="Sign up now" />
  }

  if (isPromptForDonation) {
    if (isPromoted) {
      return (
        <F
          defaultMessage="Donate to sign up with {eventOrganizationName}"
          values={{eventOrganizationName: event.organization.name}}
        />
      )
    }
    return <F defaultMessage="Donate to sign up" />
  }

  if (isPromoted) {
    return (
      <F
        defaultMessage="Donate to this {eventOrganizationName} fundraiser"
        values={{eventOrganizationName: event.organization.name}}
      />
    )
  }

  return <F defaultMessage="Donate now" />
}

export default function FundraisingForm({
  formUrl,
  onSuccess,
  event,
  organization,
  trackingParams,
  initialQueryParams,
  maybePrioritizedTimeslot,
  participationShortlink,
  shareParamsFromSignup,
  filterParams,
  eventSuggestionContext,
  owningGroups,
}) {
  const initialIdentityFields = useIdentityFields()
  const hasMobilizeIdentity = !!initialIdentityFields.email

  const [state, dispatch] = useReducer(fundraisingFormReducer, {
    dataBagIdentities: {
      actionProfileId: null,
      fastActionId: null,
      fastActionSessionId: null,
    },
    errorMessages: [],
    formDefinition: null,
    formSubmissionInProgress: false,
    isSkippingDonation: false,
    modalOpen: false,
    paymentSucceeded: false,
    paymentValuesSubmitted: {},
    prefilledFields: [],
    prefilledFieldsFromFormDefaults: [],
    prefillSource: hasMobilizeIdentity ? OnlineActionFillSource.MOBILIZE : null,
    shouldRenderVgsFields: false,
    requiredFields: {},
    signupAttemptedWithinLastFiveSeconds: false,
    signupInFlightOrCreated: false,
    signupResponse: {
      recommendedAdditionalTimeslots: [],
      shareShortlink: null,
      groupShortlink: null,
      timeslotIdForShare: null,
      userId: null,
      virtualActionRedirectURL: null,
    },
    signupValues: {
      smsOptIn: false,
    },
    signupSucceeded: false,
    submitJsForm: null,
    values: hasMobilizeIdentity
      ? convertSignupIdentityFieldsToContactInformation(initialIdentityFields)
      : {},
  })

  const {
    errorMessages,
    formDefinition,
    formSubmissionInProgress,
    isSkippingDonation,
    modalOpen,
    paymentSucceeded,
    prefillSource,
    shouldRenderVgsFields,
    requiredFields,
    signupAttemptedWithinLastFiveSeconds,
    signupInFlightOrCreated,
    signupResponse,
    signupSucceeded,
    signupValues,
    submitJsForm,
    values,
  } = state

  useEffect(() => {
    if (submitJsForm) {
      return
    }

    // Loads the library used for rendering credit card fields.
    setTimeout(initializeSubmitJs, 0)

    const intervalId = setInterval(async () => {
      if (window.submitjs) {
        clearInterval(intervalId)

        window.submitjs.allowCustomSubmissionUrl = true
        const form = window.submitjs.createForm()
        try {
          await form.initVgs()
          dispatch(setSubmitJsForm(form))
        } catch (e) {
          // TODO(ramil): Dispatch an error message that the form failed to load,
          // possibly recommending a page reload.
          logError(e)
        }
      }
    }, 100)

    return () => {
      clearInterval(intervalId)
    }
  }, [dispatch, submitJsForm])

  useEffect(() => {
    // Skip execution if formDefinition is already loaded.
    if (formDefinition) {
      return
    }

    const fetchFormDefAndPrefillData = async () => {
      let formDefinition, fastActionIdentity, fastActionValues

      try {
        formDefinition = await getFormDefinition(formUrl)
        dispatch(setFormDefinition(formDefinition))
      } catch (e) {
        logError(e)
      }

      if (!formDefinition) {
        logError(`Failed to load form Definition ${formUrl} from OA`)
        return
      }

      if (flag.isActive('kill_every_action_prefill')) {
        dispatch({type: ActionType.RENDER_VGS_FIELDS})
        return
      }

      // Attempt to retrieve the FastAction Profile id using cookies. The
      // cookies are cross-origin (.ngpvan.com) so we do not know if the cookies
      // exist before trying this request.
      try {
        if (formDefinition.fastAction) {
          fastActionIdentity = await getFastActionIdentity()
        }
      } catch (e) {
        logError(e)
      }

      if (fastActionIdentity?.id) {
        dispatch({
          type: ActionType.DATA_BAG_IDENTITY_UPDATED,
          data: {
            fastActionId: fastActionIdentity.id,
            fastActionSessionId: fastActionIdentity.fastActionSessionId,
          },
        })

        // Attempt to retrieve the FastAction Profile using the id
        try {
          const fastActionDataBag = await getFastActionDataBag(
            /* $FlowIgnore[incompatible-call] flow can't tell fastActionIdentity?.id is of type string */
            fastActionIdentity.id
          )
          fastActionValues = convertDataBagFastActionDataToContributionFormValues(
            formDefinition,
            fastActionIdentity,
            fastActionDataBag
          )
          if (!isEmpty(fastActionValues)) {
            dispatch(
              setFormValues({
                hasMobilizeIdentity,
                prefillSource: OnlineActionFillSource.FAST_ACTION,
                valuesToUpdate: fastActionValues,
              })
            )
          }
        } catch (e) {
          logError(e)
        }
      }

      // No credit card prefill available, so render VGS fields.
      if (!fastActionValues || !fastActionValues.cc_4_digit) {
        dispatch({type: ActionType.RENDER_VGS_FIELDS})
      }

      // Skip the fallback to retrieving the DataBag profile if there was fast
      // action data or the Mobilize identity exists.
      if (
        (fastActionValues && !isEmpty(fastActionValues)) ||
        hasMobilizeIdentity
      ) {
        return
      }

      // Attempt to retrieve DataBag Profile if there is no FastAction profile.
      try {
        const profileData = await getProfileDataBag()

        if (isEmpty(profileData)) {
          return
        }

        const valuesToUpdate = convertDataBagProfileDataToContributionFormValues(
          profileData
        )
        dispatch(
          setFormValues({
            prefillSource: OnlineActionFillSource.PROFILE,
            valuesToUpdate,
          })
        )
      } catch (e) {
        logError(e)
      }
    }

    // Initialize in setTimeout so it doesn't block rendering.
    setTimeout(fetchFormDefAndPrefillData, 0)
  }, [dispatch, formDefinition, formUrl, hasMobilizeIdentity])

  const location = useLocation()

  const [formInView, setFormInView] = useState(true)

  // For updating currentVolunteer and eventSignups in Redux
  const dispatchRedux = useDispatch()
  const currentVolunteer = useSelector((state) => state.currentVolunteer)

  const handleSubmitSuccess = (
    signupRequest,
    signupResponse,
    updatedCurrentVolunteer,
    signupToShareResponse,
    shouldOpenModal
  ) => {
    // Commence Mobilize actions after the payment has gone through.
    onSuccess(signupRequest, signupResponse)
    dispatch(setSignupSucceeded(shouldOpenModal, signupToShareResponse))
    dispatchRedux(setCurrentVolunteer(updatedCurrentVolunteer))
    dispatchRedux(
      setEventSignups(
        event.id,
        signupRequest.shifts.map((s) => s.timeslot_id)
      )
    )
  }

  const handleChange = (name, value) => {
    dispatch(setFormValues({valuesToUpdate: {[name]: value}}))
  }

  const handleSignupValueChange = (name, value) => {
    dispatch(setSignupValues({[name]: value}))
  }

  const handleModalState = (isOpen) => {
    dispatch(setModalState(isOpen))
  }

  const handleSkippingDonationState = (isSkippingDonation) => {
    dispatch(setSkippingDonationState(isSkippingDonation))
  }

  const handleSubmit = async () => {
    if (!formDefinition) {
      console.warn('formDefinition was not set.')
      return
    }

    if (!submitJsForm) {
      logError('Submit form was called before submitJsForm was initialized.')
      return
    }

    setTimeout(() => {
      dispatch({type: ActionType.SIGNUP_ATTEMPTED_TIMEOUT_ELAPSED})
    }, 5000)
    dispatch({type: ActionType.PAYMENT_SUBMITTED})

    const context = {
      event,
      organization,
      trackingParams,
      location,
      filterParams,
      eventSuggestionContext,
      signupValues,
    }

    const paymentValues = constructSubmitJsPayload(state)

    await submitPaymentAndSignup(
      context,
      dispatch,
      handleSubmitSuccess,
      isSkippingDonation,
      submitJsForm,
      formDefinition,
      paymentValues
    )
    // ActionType.SIGNUP_SUCCEEDED is dispatched in handleSubmitSuccess callback.
  }

  const identityFieldsFromValues = getIdentityFieldsFromContributionFormValues(
    // Since the not required fields are not displayed on this form, and the
    // supporter cannot view them to validate them, we should not assume their
    // validity and will not pass them to child components.
    filterNotRequiredAddressFields(values, requiredFields)
  )

  let totalDonationAmount = parseFloat(values.SelectAmount)
  if (values.CoverCostsAmount && values.CoverCostsAmountCalculated) {
    totalDonationAmount += parseFloat(values.CoverCostsAmountCalculated)
  }

  // Empty state - fundraiser over
  const eventIsOver = !event.times?.length
  if (eventIsOver) {
    return <EventClosedBox event={event} displayEventAsPassed={true} />
  }

  return (
    // TODO: Wrap this in an error boundary for safety.
    <>
      <Form className="fundraising-form">
        <Card
          header={
            <FundraisingFormHeader
              event={event}
              organization={organization}
              isSkippingDonation={isSkippingDonation}
            />
          }
          data-testid="fundraising_form"
        >
          {formDefinition && submitJsForm ? (
            <FundraisingFormFields
              dispatch={dispatch}
              isSubmitting={formSubmissionInProgress}
              formDefinition={formDefinition}
              isSkippingDonation={isSkippingDonation}
              isSuccess={paymentSucceeded && signupSucceeded}
              maybePrioritizedTimeslot={maybePrioritizedTimeslot}
              onChange={handleChange}
              onFormInViewChange={setFormInView}
              onSignupValueChange={handleSignupValueChange}
              onSubmit={handleSubmit}
              values={values}
              event={event}
              organization={organization}
              prefillSource={prefillSource}
              setIsSkippingDonation={handleSkippingDonationState}
              shouldRenderVgsFields={shouldRenderVgsFields}
              signupValues={signupValues}
              submitJsForm={submitJsForm}
            />
          ) : (
            <Loader />
          )}
          {errorMessages && errorMessages.length > 0 && (
            <Message
              type={MessageType.ERROR}
              header={errorMessages[0]}
              list={errorMessages.length > 1 ? errorMessages.slice(1) : []}
            />
          )}
        </Card>
        <Sticky
          currentVolunteer={currentVolunteer}
          event={event}
          expandActLater={false}
          identityFields={identityFieldsFromValues}
          initialQueryParams={initialQueryParams}
          organization={organization}
          participationShortlink={participationShortlink}
          shareParamsFromSignup={shareParamsFromSignup}
          shouldDisplay={!formInView}
          signupCreatedWithinLastFiveSeconds={
            signupAttemptedWithinLastFiveSeconds
          }
          signupInFlightOrCreated={signupInFlightOrCreated}
          isGroupAndAlreadyJoined={false}
        />
        <PostModals
          currentVolunteer={currentVolunteer}
          event={event}
          expandActLater={false}
          identityFields={identityFieldsFromValues}
          modalOpen={modalOpen}
          organization={organization}
          setModalOpen={handleModalState}
          signupResponse={signupResponse}
          trackingParams={trackingParams}
          donationAmount={(!isSkippingDonation && totalDonationAmount) || null}
          owningGroups={owningGroups}
        />
      </Form>
    </>
  )
}
