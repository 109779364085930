import {Alert} from '@material-ui/lab'
import {F} from '../../util/i18n'
import styled from '@emotion/styled/macro'

const AlertWrapper = styled.div`
  width: 100%;
`

export default function SpecialCaseInfoBanner({
  event,
  checkInStartTime,
  displayEventAsPassed,
  displayEventAsFull,
}) {
  const tooEarlyForCheckIn =
    checkInStartTime && new Date(checkInStartTime) > new Date()
  return displayEventAsPassed ? (
    <AlertWrapper>
      <Alert severity="info">
        <F defaultMessage="Event ended." />
      </Alert>
    </AlertWrapper>
  ) : tooEarlyForCheckIn ? (
    <AlertWrapper>
      <Alert severity="warning">
        <F defaultMessage="Too early to check in." />
      </Alert>
    </AlertWrapper>
  ) : displayEventAsFull ? (
    <AlertWrapper>
      <Alert severity="info">
        <F defaultMessage="Event at capacity." />
      </Alert>
    </AlertWrapper>
  ) : null
}
