import { ValuesOf } from 'types'
import {createIntl, defineMessages} from 'util/i18n'

import UsTimezone from 'data/us-timezones'
import {invert} from 'lodash'
import momentTimezoneData from 'vendor/moment-timezone-data.json'

export const StyleEnum = Object.freeze({
  DEFAULT: 'default',
  MINIMAL: 'minimal',
})

export const TimezoneSource = Object.freeze({
  USER_INPUT: 1,
  ZIPCODE_INFERRED: 2,
})

export const CountryCode = Object.freeze({
  UNITED_STATES: 'US',
  PUERTO_RICO: 'PR',
  US_VIRGIN_ISLANDS: 'VI',
  AMERICAN_SAMOA: 'AS',
  NORTHERN_MARIANA_ISLANDS: 'MP',
  GUAM: 'GU',
})

// Keep in sync with lib.i18n.LocaleName
export const LocaleName = Object.freeze({
  EN: 'en',
  ES: 'es',
})

/** Main U.S. timezones. Keep in sync with lib.datetime */
export const Timezone = Object.freeze({
  AMERICAN_SAMOA: 'Pacific/Pago_Pago', // American Samoa
  HAWAII: 'Pacific/Honolulu',
  HAWAII_ALEUTIAN: 'America/Adak',
  ALASKA: 'America/Anchorage',
  ALASKA_METLAKATLA: 'America/Metlakatla',
  PACIFIC: 'America/Los_Angeles',
  ARIZONA: 'America/Phoenix',
  MOUNTAIN: 'America/Denver',
  CENTRAL: 'America/Chicago',
  EASTERN: 'America/New_York',
  ATLANTIC: 'America/Puerto_Rico', // Puerto Rico, U.S. Virgin Islands
  CHAMORRO: 'Pacific/Guam', // Guam, Northern Mariana Islands
})

// Corresponds to backend timezone constants in lib.datetime
// (This is located in a separate file because the script that generates timezone data for
// moment-timezone, scripts/prepare-moment-timezone-data.js, needs the data also and can't
// use es6 imports. We re-export it here for convenience.)
export {UsTimezone}

export const ExperienceFeedbackType = Object.freeze({
  APPROVED_OF_SHIFT: 1,
  DISAPPROVED_OF_SHIFT: 2,
  DID_NOT_ATTEND: 3,
  // NEXT ID: 4,
  NO_FEEDBACK_TYPE: 'NO_FEEDBACK_TYPE',
})

export const OrgDomain = Object.freeze({
  ELECTORAL_COORDINATED: 'ELECTORAL_COORDINATED',
  ELECTORAL_INDEPENDENT: 'ELECTORAL_INDEPENDENT',
  NONELECTORAL: 'NONELECTORAL',
})

export const AccessibilityFeature = Object.freeze({
  ACCESSIBLE_RESTROOMS: 'ACCESSIBLE_RESTROOMS',
  DEDICATED_PARKING_SPOTS: 'DEDICATED_PARKING_SPOTS',
  MAINLY_FLAT_GROUND: 'MAINLY_FLAT_GROUND',
  WHEELCHAIR_RAMP: 'WHEELCHAIR_RAMP',
  NO_STAIRS_OR_STEPS: 'NO_STAIRS_OR_STEPS',
  WIDE_DOORWAYS_AND_WALKWAYS: 'WIDE_DOORWAYS_AND_WALKWAYS',
  DEDICATED_SEATING: 'DEDICATED_SEATING',
  LIVE_CAPTIONING: 'LIVE_CAPTIONING',
  AUDIO_DESCRIPTIONS_FOR_VIDEO: 'AUDIO_DESCRIPTIONS_FOR_VIDEO',
  ASL_INTERPRETATION: 'ASL_INTERPRETATION',
  SPANISH_INTERPRETATION: 'SPANISH_INTERPRETATION',
  COVID_SAFETY_PROTOCOLS: 'COVID_SAFETY_PROTOCOLS',
})

export const EventType = Object.freeze({
  CANVASS: 1,
  PHONE_BANK: 2,
  TEXT_BANK: 3,
  MEETING: 4,
  COMMUNITY: 5,
  FUNDRAISER: 6,
  OTHER: 7,
  MEET_GREET: 8,
  HOUSE_PARTY: 9,
  VOTER_REG: 10,
  TRAINING: 11,
  FRIEND_TO_FRIEND_OUTREACH: 12,
  ADVOCACY_CALL: 14,
  DEBATE_WATCH_PARTY: 15,
  RALLY: 16,
  TOWN_HALL: 17,
  OFFICE_OPENING: 18,
  BARNSTORM: 19,
  SOLIDARITY_EVENT: 20,
  COMMUNITY_CANVASS: 21,
  SIGNATURE_GATHERING: 22,
  CARPOOL: 23,
  WORKSHOP: 24,
  PETITION: 25,
  AUTOMATED_PHONE_BANK: 26,
  LETTER_WRITING: 27,
  LITERATURE_DROP_OFF: 28,
  VISIBILITY_EVENT: 29,
  PLEDGE: 30,
  INTEREST_FORM: 31,
  DONATION_CAMPAIGN: 32,
  SOCIAL_MEDIA_CAMPAIGN: 33,
  POSTCARD_WRITING: 34,
  GROUP: 35,
  VOLUNTEER_SHIFT: 36,
  ADVOCACY: 37,
  RELATIONAL: 38,
  DATA_ENTRY: 39,
  POLL_MONITORING: 40,
  HOTLINE: 41,
  VOTER_PROTECTION: 42,
})

export const EventSuggestionType = Object.freeze({
  EVENT_TO_EVENT: 1,
  EVENT_TO_FEED_FILTER_PARAMS: 2,
  ORGANIZATION_TO_EVENT: 3,
  ORGANIZATION_TO_FEED_FILTER_PARAMS: 4,
})

export const EventVisibility = Object.freeze({
  PUBLIC: 1,
  PRIVATE: 3,
})

export const EventExternalSync = Object.freeze({
  FROM_MOBILIZE: 1,
  TO_MOBILIZE_FROM_VAN_STATEWIDE: 2,
})

export const EventApprovalStatus = Object.freeze({
  APPROVED: 1,
  NEEDS_APPROVAL: 2,
  REJECTED: 3,
  NEEDS_HOST_VERIFICATION: 4,
})

export const CustomSignupFieldType = Object.freeze({
  BOOLEAN: 1,
  SHORT_TEXT: 2,
  LONG_TEXT: 3,
  DROPDOWN: 4,
})

export const AccessibilityStatus = Object.freeze({
  ACCESSIBLE: 1,
  NOT_ACCESSIBLE: 2,
  NOT_SURE: 3,
})

export const PermissionTier = Object.freeze({
  ADMIN: 1,
  ORGANIZER: 2,
  TRUSTED_HOST: 3,
  HOST: 4,
})

export const EventCampaignStatus = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
  ENDED: 3,
})

export const RaceType = Object.freeze({
  GOVERNOR: 1,
  CONGRESSIONAL: 2,
  SENATE: 3,
  STATE_SENATE: 4,
  STATE_LEG: 5,
  SEC_STATE: 6,
  ATTY_GENERAL: 7,
  OTHER_LOCAL: 8,
  OTHER_STATEWIDE: 9,
  PRESIDENTIAL: 10,
  BALLOT_INITIATIVE: 11,
})

export const DistrictVendorType = Object.freeze({
  NGPVAN: 'NGPVAN',
  BALLOT_READY: 'BALLOT_READY',
})

export const OrgType = Object.freeze({
  CAMPAIGN: 1,
  PARTY_COMMITTEE: 2,
  DEPRECATED_COORDINATED: 3,
  DEPRECATED_INDEPENDENT: 4,
  C3: 5,
  C4: 6,
  UNION: 7,
  GOVERNMENT: 8,
  PAC: 9,
  GRASSROOTS_GROUP: 10,
  OTHER: 11,
  INDIVIDUAL: 12,
})

export const AccountTier = Object.freeze({
  ENTERPRISE: 1,
  PRO: 2,
  CORE: 3,
  GRASSROOTS: 4,
  LEGACY: 5,
  BASIC: 6,
  STARTER: 7,
  STARTUP: 8,
  GROWTH: 9,
  CORE_PLUS: 10,
})

export const PostSignupAsk = Object.freeze({
  SOCIAL_SHARING: 1,
  DONATION: 2,
  EVENT_SUGGESTIONS: 3,
  SHIFT_SUGGESTIONS: 4,
})

export const OrganizationPromotionRequestStatus = Object.freeze({
  REQUESTED: 1,
  APPROVED: 2,
  IGNORED: 3,
})

export const EventTypeValues = new Set(
  Object.keys(EventType).map((k) => EventType[k])
)

/** Keep in sync with event.enums.EventApprovalMode */
export const EventApprovalMode = Object.freeze({
  ADMINS_ONLY: 1,
  ADMINS_AND_MODERATORS: 2,
  NO_APPROVAL_REQUIRED: 3,
})

/** Keep in sync with event.enums.EventRegistrationMode */
export const EventRegistrationMode = Object.freeze({
  DEFAULT: 1,
  DONATE_TO_RSVP: 2,
  DONATE_TO_RSVP_OPTIONAL: 3,
})

export const PermissionTierToDisplayName = Object.freeze({
  [PermissionTier.ADMIN]: 'Admin',
  [PermissionTier.ORGANIZER]: 'Organizer',
  [PermissionTier.TRUSTED_HOST]: 'Trusted host',
  [PermissionTier.HOST]: 'Host',
})

// Names coincide with PermissionTiers for now, but this may change.
export const DashboardOrganizersScreenTab = Object.freeze({
  ADMIN: 1,
  ORGANIZER: 2,
  TRUSTED_HOST: 3,
  HOST: 4,
  ALL: 5,
})

export const DashboardOrganizersScreenTabToPermissionTier = {
  [DashboardOrganizersScreenTab.ADMIN]: PermissionTier.ADMIN,
  [DashboardOrganizersScreenTab.ORGANIZER]: PermissionTier.ORGANIZER,
  [DashboardOrganizersScreenTab.TRUSTED_HOST]: PermissionTier.TRUSTED_HOST,
  [DashboardOrganizersScreenTab.HOST]: PermissionTier.HOST,
  [DashboardOrganizersScreenTab.ALL]: null,
}

export const DashboardOrganizersScreenTabToPermissionTierName = {
  [DashboardOrganizersScreenTab.ADMIN]: 'Admin',
  [DashboardOrganizersScreenTab.ORGANIZER]: 'Organizer',
  [DashboardOrganizersScreenTab.TRUSTED_HOST]: 'Trusted host',
  [DashboardOrganizersScreenTab.HOST]: 'Host',
  [DashboardOrganizersScreenTab.ALL]: 'All',
}

export const DashboardVolunteersScreenTab = Object.freeze({
  ALL: 1,
  BLOCKED: 2,
})

export const DashboardStatsScreenTab = Object.freeze({
  SOURCES: 1,
  PROGRAM: 2,
  CONVERSION: 3,
  VOLUNTEERS: 4,
  TEAM: 5,
  PROMOTIONS: 6,
  SUBACCOUNTS: 7,
  INTEGRATION_ERRORS: 8,
})

// Keep aligned with event.enums.DashboardEventDetailTimeslotsFilter
export const DashboardEventDetailsTimeslotsTab = Object.freeze({
  ALL_TIMESLOTS: 1,
  UPCOMING_TIMESLOTS: 2,
  PAST_TIMESLOTS: 3,
})

export const dashboardEventDetailsTimeslotsTabToText = (tab: ValuesOf<typeof DashboardEventDetailsTimeslotsTab>) => {
  switch (tab) {
    case DashboardEventDetailsTimeslotsTab.ALL_TIMESLOTS:
      return 'All shifts'
    case DashboardEventDetailsTimeslotsTab.UPCOMING_TIMESLOTS:
      return 'Upcoming'
    case DashboardEventDetailsTimeslotsTab.PAST_TIMESLOTS:
      return 'Past'
    default:
      return ''
  }
}

// Keep in sync with event.enums.DashboardEventsScreenTab
export const DashboardEventsScreenTab = Object.freeze({
  UPCOMING_EVENTS: 1,
  PAST_EVENTS: 2,
  PROMOTED_EVENTS: 3,
  AWAITING_APPROVAL_EVENTS: 4,
  AWAITING_VERIFICATION_EVENTS: 5,
  ALL_EVENTS: 6,
  DELETED_EVENTS: 7,
})

export const dashboardEventTabToText = (tab) => {
  switch (tab) {
    case DashboardEventsScreenTab.UPCOMING_EVENTS:
      return 'Upcoming'
    case DashboardEventsScreenTab.PAST_EVENTS:
      return 'Past'
    case DashboardEventsScreenTab.PROMOTED_EVENTS:
      return 'Promoted'
    case DashboardEventsScreenTab.AWAITING_APPROVAL_EVENTS:
      return EventApprovalStatusToDisplayName[
        EventApprovalStatus.NEEDS_APPROVAL
      ]
    case DashboardEventsScreenTab.AWAITING_VERIFICATION_EVENTS:
      return 'Awaiting Verification'
    case DashboardEventsScreenTab.DELETED_EVENTS:
      return 'Deleted'
    case DashboardEventsScreenTab.ALL_EVENTS:
      return 'All Events - Mobilize Only'
    default:
      return ''
  }
}

export const DashboardPromotionsScreenTab = Object.freeze({
  PROMOTE_OTHERS: 1,
  MY_PROMOTERS: 2,
  FILTERS: 3,
})

export const DashboardSignupsScreenTab = Object.freeze({
  COMPLETED_SIGNUPS: 1,
  CONFIRMED_SIGNUPS: 2,
  REGISTERED_SIGNUPS: 3,
  NO_SHOW_SIGNUPS: 4,
  UNKNOWN_SIGNUPS: 5,
  CANCELLED_SIGNUPS: 6,
  ALL_SIGNUPS: 7,
})

export const OrganizationDomain = Object.freeze({
  ELECTORAL_INDEPENDENT: 'ELECTORAL_INDEPENDENT',
  ELECTORAL_COORDINATED: 'ELECTORAL_COORDINATED',
  NONELECTORAL: 'NONELECTORAL',
})

export const CarouselCategory = Object.freeze({
  MORE_FROM_EVENT_OWNING_ORG: 'more_from_event_owning_org',
  HIGH_PRIORITY: 'high_priority',
  NEAR_YOU: 'near_you',
  POPULAR_VIRTUAL: 'popular_virtual',
  POPULAR_VIRTUAL_FLEXIBLE: 'popular_virtual_flexible',
  MORE_WITH_TAGS: 'more_with_tags',
  ORGS_WITH_UPCOMING_EVENTS: 'orgs_with_upcoming_events',
  GROUP_LINKED_EVENTS: 'group_linked_events',
  GROUPS_FOR_ORG: 'groups_for_org',
  MORE_OF_TYPES: 'more_of_types',
  TRENDING_EVENTS: 'trending_events',
  STATEWIDE: 'statewide',
})

export function orgTypeToDisplayName(orgType: ValuesOf<typeof OrgType>) {
  const map = {
    [OrgType.CAMPAIGN]: 'Political campaign',
    [OrgType.PARTY_COMMITTEE]: 'Party committee',
    [OrgType.C3]: '501(c)(3) nonprofit',
    [OrgType.C4]: '501(c)(4) nonprofit',
    [OrgType.UNION]: 'Labor union',
    [OrgType.GOVERNMENT]: 'Government entity',
    [OrgType.PAC]: 'PAC',
    [OrgType.GRASSROOTS_GROUP]: 'Grassroots group',
    [OrgType.OTHER]: 'Other',
    [OrgType.INDIVIDUAL]: 'Individual',
  }
  if (!map[orgType]) return ''
  return map[orgType]
}

export const ORG_TYPE_INT_VALUES = new Set(
  Object.keys(OrgType).map((k) => OrgType[k])
)

// Keep in sync with event.enums.REGISTRATION_ONLY_EVENT_TYPES
export const RegistrationOnlyEventTypes = new Set([
  EventType.PLEDGE,
  EventType.INTEREST_FORM,
  EventType.DONATION_CAMPAIGN,
  EventType.PETITION,
  EventType.GROUP,
])

// TODO(jared): figure out conceptual overlap with RegistrationOnlyEventTypes (it's the same except
// no DONATION_CAMPAIGN)
export const VIRTUAL_FLEXIBLE_EVENT_TYPES_TO_SHOW_ONE_TAP_SIGNUP = new Set([
  EventType.PLEDGE,
  EventType.INTEREST_FORM,
  EventType.PETITION,
  EventType.GROUP,
])

// Keep in sync with EVENT_TYPE_DISPLAY_NAMES in event/enums.py
/**
 * DEPRECATION NOTE: This function only returns English strings, and as such, is
 * deprecated in favor of `app/enumMessages.ts::eventTypeToDisplayMessage`.
 * Please do not use this function in any new code.
 */
export function eventTypeToDisplayName(eventType: ValuesOf<typeof EventType>, virtual = false) {
  const map = {
    [EventType.CANVASS]: 'Canvass',
    [EventType.PHONE_BANK]: 'Phone Bank',
    [EventType.TEXT_BANK]: 'Text Bank',
    [EventType.MEETING]: 'Meeting',
    [EventType.COMMUNITY]: 'Community Event',
    [EventType.FUNDRAISER]: 'Fundraiser',
    [EventType.MEET_GREET]: 'Meet-and-Greet',
    [EventType.HOUSE_PARTY]: 'House Party',
    [EventType.VOTER_REG]: 'Voter Registration',
    [EventType.TRAINING]: 'Training',
    [EventType.FRIEND_TO_FRIEND_OUTREACH]: 'Friend-to-Friend Outreach',
    [EventType.ADVOCACY_CALL]: 'Call Your Representative',
    [EventType.DEBATE_WATCH_PARTY]: 'Watch Party',
    [EventType.RALLY]: 'Rally',
    [EventType.TOWN_HALL]: 'Town Hall',
    [EventType.OFFICE_OPENING]: 'Office Opening',
    [EventType.BARNSTORM]: 'Barnstorm',
    [EventType.SOLIDARITY_EVENT]: 'Solidarity Event',
    [EventType.COMMUNITY_CANVASS]: 'Community Canvass',
    [EventType.SIGNATURE_GATHERING]: 'Signature Gathering',
    [EventType.CARPOOL]: 'Carpool',
    [EventType.OTHER]: virtual ? 'Event' : 'Other',
    [EventType.WORKSHOP]: 'Workshop',
    [EventType.PETITION]: 'Petition',
    [EventType.AUTOMATED_PHONE_BANK]: 'Automated Phone Bank',
    [EventType.LETTER_WRITING]: 'Letter Writing',
    [EventType.LITERATURE_DROP_OFF]: 'Literature Drop-off',
    [EventType.VISIBILITY_EVENT]: 'Visibility Event',
    [EventType.PLEDGE]: 'Pledge',
    [EventType.INTEREST_FORM]: 'Interest Form',
    [EventType.DONATION_CAMPAIGN]: 'Fundraising Campaign',
    [EventType.SOCIAL_MEDIA_CAMPAIGN]: 'Social Media Campaign',
    [EventType.POSTCARD_WRITING]: 'Postcard Writing',
    [EventType.GROUP]: 'Group',
    [EventType.VOLUNTEER_SHIFT]: 'Volunteer Shift',
    [EventType.ADVOCACY]: 'Advocacy Event',
    [EventType.RELATIONAL]: 'Relational',
    [EventType.DATA_ENTRY]: 'Data Entry',
    [EventType.POLL_MONITORING]: 'Poll Monitoring',
    [EventType.HOTLINE]: 'Hotline',
    [EventType.VOTER_PROTECTION]: 'Voter Protection'
  }
  if (!map[eventType]) return ''
  return virtual ? `Virtual ${map[eventType]}` : map[eventType]
}

// Keep in sync with EVENT_TYPE_TO_PLURAL_NOUN in event/enums.py
export function eventTypeToPluralDisplayName(eventType: ValuesOf<typeof EventType>) {
  const map = {
    [EventType.CANVASS]: 'Canvasses',
    [EventType.PHONE_BANK]: 'Phone Banks',
    [EventType.TEXT_BANK]: 'Text Banks',
    [EventType.MEETING]: 'Meetings',
    [EventType.COMMUNITY]: 'Community Events',
    [EventType.FUNDRAISER]: 'Fundraisers',
    [EventType.MEET_GREET]: 'Meet-and-Greets',
    [EventType.HOUSE_PARTY]: 'House Parties',
    [EventType.VOTER_REG]: 'Voter Registrations',
    [EventType.TRAINING]: 'Trainings',
    [EventType.FRIEND_TO_FRIEND_OUTREACH]: 'Friend-to-Friend Outreaches',
    [EventType.ADVOCACY_CALL]: 'Representative Calls',
    [EventType.DEBATE_WATCH_PARTY]: 'Watch Parties',
    [EventType.RALLY]: 'Rallies',
    [EventType.TOWN_HALL]: 'Town Halls',
    [EventType.OFFICE_OPENING]: 'Office Openings',
    [EventType.BARNSTORM]: 'Barnstorms',
    [EventType.SOLIDARITY_EVENT]: 'Solidarity Events',
    [EventType.COMMUNITY_CANVASS]: 'Community Canvasses',
    [EventType.SIGNATURE_GATHERING]: 'Signature Gatherings',
    [EventType.CARPOOL]: 'Carpools',
    [EventType.OTHER]: 'Other events',
    [EventType.WORKSHOP]: 'Workshops',
    [EventType.PETITION]: 'Petitions',
    [EventType.AUTOMATED_PHONE_BANK]: 'Automated Phone Banks',
    [EventType.LETTER_WRITING]: 'Letter Writing Events',
    [EventType.LITERATURE_DROP_OFF]: 'Literature Drop-offs',
    [EventType.VISIBILITY_EVENT]: 'Visibility Events',
    [EventType.PLEDGE]: 'Pledges',
    [EventType.INTEREST_FORM]: 'Interest Forms',
    [EventType.DONATION_CAMPAIGN]: 'Fundraising Campaigns',
    [EventType.SOCIAL_MEDIA_CAMPAIGN]: 'Social Media Campaigns',
    [EventType.POSTCARD_WRITING]: 'Postcard Writing Events',
    [EventType.GROUP]: 'Groups',
    [EventType.VOLUNTEER_SHIFT]: 'Volunteer Shifts',
    [EventType.ADVOCACY]: 'Advocacy Events',
    [EventType.RELATIONAL]: 'Friends and Family Outreach Events',
    [EventType.DATA_ENTRY]: 'Data Entry Events',
    [EventType.POLL_MONITORING]: 'Poll Monitoring Shifts',
    [EventType.HOTLINE]: 'Hotline Shifts',
    [EventType.VOTER_PROTECTION]: 'Voter Protection Events'
  }
  return map[eventType] || ''
}

// @TODO return type should be a flow string enum here?
export function eventVisibilityToDisplayName(eventVisibility: ValuesOf<typeof EventVisibility>) {
  const map = {
    [EventVisibility.PUBLIC]: 'Public',
    [EventVisibility.PRIVATE]: 'Private',
  }
  return map[eventVisibility] || ''
}

export function raceTypeToDisplayName(raceType: ValuesOf<typeof RaceType>) {
  const map = {
    [RaceType.GOVERNOR]: 'Governor',
    [RaceType.CONGRESSIONAL]: 'Congressional',
    [RaceType.SENATE]: 'Senate',
    [RaceType.STATE_SENATE]: 'State Senate',
    [RaceType.STATE_LEG]: 'State Legislature',
    [RaceType.SEC_STATE]: 'Secretary of State',
    [RaceType.ATTY_GENERAL]: 'Attorney General',
    [RaceType.OTHER_LOCAL]: 'Local',
    [RaceType.OTHER_STATEWIDE]: 'Statewide',
    [RaceType.PRESIDENTIAL]: 'Presidential',
    [RaceType.BALLOT_INITIATIVE]: 'Ballot Initiative',
  }
  return map[raceType] || ''
}

export const RACE_TYPE_INT_VALUES = new Set(
  Object.keys(RaceType).map((key) => RaceType[key])
)

/** Keep in sync with event.enums.ParticipationStatus */
export const ParticipationStatus = Object.freeze({
  REGISTERED: 1,
  CANCELLED: 2,
  CONFIRMED: 5,
  // reserved: 3, 4
  // NEXT ID: 6
})

/** Keep in sync with event.enums.AttendeeCheckInStatus */
export const AttendeeCheckInStatus = Object.freeze({
  COMPLETED: 1,
  CANCELLED: 2,
  // NEXT ID: 3
})

/** Keep in sync with event.enums.AttendeeCheckInStatusUpdateMethod */
export const AttendeeCheckInStatusUpdateMethod = Object.freeze({
  EMAIL: 'EMAIL',
  VOLUNTEER_SCHEDULE: 'VOLUNTEER_SCHEDULE',
  PARTICIPATION_JOIN_REDIRECT: 'PARTICIPATION_JOIN_REDIRECT',
  SELF_SERVICE: 'SELF_SERVICE',
  GROUP_LEADER_SELF_SERVICE: 'GROUP_LEADER_SELF_SERVICE',
})

/** Keep in sync with event.enums.VolunteerFeedbackMethod */
export const VolunteerFeedbackMethod = Object.freeze({
  SMS: 1,
  EMAIL: 2,
  SCHEDULE: 3,
  // NEXT ID: 4
})

/** Keep in sync with event.enums.AttendanceStatus */
export const AttendanceStatus = Object.freeze({
  COMPLETED: 1,
  NO_SHOW: 2,
  CANCELLED: 3,
  REGISTERED: 4,
  CONFIRMED: 5,
  // NEXT ID: 6
})

/** N.B.(margot) This is needed because we have a FE-only construct of an "Unknown" attendance
 * status. Currently this only refers to Past timeslots' "Registered" and "Confirmed" statuses,
 * which get displayed as "Unknown" once the timeslot is over. This enum shouldn't be used to send
 * data to the backend, only for displaying options for the user to choose from, and we should use
 * translateAttendanceStatus() before sending any requests that have used this enum on the FE.
 */
export const AttendanceStatusForDisplay = Object.freeze({
  COMPLETED: 1,
  NO_SHOW: 2,
  CANCELLED: 3,
  REGISTERED: 4,
  CONFIRMED: 5,
  UNKNOWN: 6,
  // NEXT ID: 7
})

/** Keep in sync with messaging.enums.PotentialHostsFilterCriteria */
export const PotentialHostsFilterCriteria = Object.freeze({
  HOSTED_BEFORE: 1,
  HAS_COMMITTED: 2,
  // NEXT ID: 3
})

/** Keep in sync with messaging.enums.PastAttendeeFilterCriteria */
export const PastAttendeeFilterCriteria = Object.freeze({
  ALL_ORGANIZATIONS: 1,
  CURRENT_ORGANIZATION_ONLY_FOR_HOST: 2,
  CURRENT_ORGANIZATION_ALL: 3,
  // NEXT ID: 4
})

export const TimeslotFilterCriteria = Object.freeze({
  UPCOMING_ONLY: 1,
  PAST_ONLY: 2,
  // NEXT ID: 3
})

export const AttendanceStatusesPerDashboardSignupsScreenTab = Object.freeze({
  [DashboardSignupsScreenTab.COMPLETED_SIGNUPS]: [AttendanceStatus.COMPLETED],
  [DashboardSignupsScreenTab.CANCELLED_SIGNUPS]: [AttendanceStatus.CANCELLED],
  [DashboardSignupsScreenTab.CONFIRMED_SIGNUPS]: [AttendanceStatus.CONFIRMED],
  [DashboardSignupsScreenTab.NO_SHOW_SIGNUPS]: [AttendanceStatus.NO_SHOW],
  [DashboardSignupsScreenTab.REGISTERED_SIGNUPS]: [AttendanceStatus.REGISTERED],
  [DashboardSignupsScreenTab.ALL_SIGNUPS]: [
    AttendanceStatus.COMPLETED,
    AttendanceStatus.CANCELLED,
    AttendanceStatus.CONFIRMED,
    AttendanceStatus.REGISTERED,
    AttendanceStatus.NO_SHOW,
  ],
  [DashboardSignupsScreenTab.UNKNOWN_SIGNUPS]: [
    AttendanceStatus.REGISTERED,
    AttendanceStatus.CONFIRMED,
  ],
})

export const attendanceStatusToDisplayName = Object.freeze({
  [AttendanceStatus.REGISTERED]: 'Registered',
  [AttendanceStatus.CANCELLED]: 'Cancelled',
  [AttendanceStatus.CONFIRMED]: 'Confirmed',
  [AttendanceStatus.COMPLETED]: 'Completed',
  [AttendanceStatus.NO_SHOW]: 'No-show',
})

export const attendanceStatusForDisplayToDisplayName = Object.freeze({
  [AttendanceStatusForDisplay.REGISTERED]: 'Registered',
  [AttendanceStatusForDisplay.CANCELLED]: 'Cancelled',
  [AttendanceStatusForDisplay.CONFIRMED]: 'Confirmed',
  [AttendanceStatusForDisplay.COMPLETED]: 'Completed',
  [AttendanceStatusForDisplay.NO_SHOW]: 'No-show',
  [AttendanceStatusForDisplay.UNKNOWN]: 'Unknown status',
})

export const potentialHostsFilterCriteriaToDisplayName = Object.freeze({
  [PotentialHostsFilterCriteria.HAS_COMMITTED]: 'Committed to host',
  [PotentialHostsFilterCriteria.HOSTED_BEFORE]: 'Hosted events before',
})

export function participationStatusToDisplayName(ps) {
  const map = {
    [ParticipationStatus.REGISTERED]: 'Registered',
    [ParticipationStatus.CANCELLED]: 'Cancelled',
    [ParticipationStatus.CONFIRMED]: 'Confirmed',
  }
  return map[ps] || ''
}

export const HostCommitmentSource = Object.freeze({
  EVENT_CAMPAIGN_DISCOVERY_PAGE: 1,
  SMS: 2,
  EMAIL: 4,
  // NEXT ID: 5
})

// Corresponds to lib.datetime.PRIMARY_TZ_DISPLAY_NAMES (though the actual display strings differ slightly)
export const TimezoneToString = Object.freeze({
  [Timezone.EASTERN]: 'Eastern Time',
  [Timezone.CENTRAL]: 'Central Time',
  [Timezone.MOUNTAIN]: 'Mountain Time',
  [Timezone.ARIZONA]: 'Arizona',
  [Timezone.PACIFIC]: 'Pacific Time',
  [Timezone.ALASKA]: 'Alaska',
  [Timezone.HAWAII]: 'Hawaii',
  [Timezone.HAWAII_ALEUTIAN]: 'Hawaii-Aleutian Time (Adak)',
  [Timezone.ALASKA_METLAKATLA]: 'Alaska Time - Metlakatla',
  [Timezone.ATLANTIC]: 'Atlantic Time',
  [Timezone.CHAMORRO]: 'Chamorro Time',
  [Timezone.AMERICAN_SAMOA]: 'Samoa Time',
})

// Corresponds to lib.datetime.EXTENDED_TZ_DISPLAY_NAMES
export const UsTimezoneToString = Object.freeze({
  [UsTimezone.AMERICAN_SAMOA]: 'Samoa Time - Pago Pago, American Samoa',
  [UsTimezone.HAWAII]: 'Hawaii Time - Honolulu',
  [UsTimezone.HAWAII_ALEUTIAN]: 'Hawaii-Aleutian Time (Adak)',
  [UsTimezone.ALASKA]: 'Alaska Time - Anchorage',
  [UsTimezone.ALASKA_JUNEAU]: 'Alaska Time - Juneau',
  [UsTimezone.ALASKA_SITKA]: 'Alaska Time - Sitka',
  [UsTimezone.ALASKA_METLAKATLA]: 'Alaska Time - Metlakatla',
  [UsTimezone.ALASKA_YAKUTAT]: 'Alaska Time - Yakutat',
  [UsTimezone.ALASKA_NOME]: 'Alaska Time - Nome',
  [UsTimezone.PACIFIC]: 'Pacific Time - Los Angeles',
  [UsTimezone.ARIZONA]: 'Arizona Time - Phoenix',
  [UsTimezone.MOUNTAIN]: 'Mountain Time - Denver',
  [UsTimezone.MOUNTAIN_BOISE]: 'Mountain Time - Boise',
  [UsTimezone.CENTRAL]: 'Central Time - Chicago',
  [UsTimezone.CENTRAL_INDIANA_TELL_CITY]: 'Central Time - Tell City, Indiana',
  [UsTimezone.CENTRAL_INDIANA_KNOX]: 'Central Time - Knox, Indiana',
  [UsTimezone.CENTRAL_MENOMINEE]: 'Central Time - Menominee, Michigan',
  [UsTimezone.CENTRAL_NORTH_DAKOTA_CENTER]:
    'Central Time - Center, North Dakota',
  [UsTimezone.CENTRAL_NORTH_DAKOTA_NEW_SALEM]:
    'Central Time - New Salem, North Dakota',
  [UsTimezone.CENTRAL_NORTH_DAKOTA_BEULAH]:
    'Central Time - Beulah, North Dakota',
  [UsTimezone.EASTERN]: 'Eastern Time - New York',
  [UsTimezone.EASTERN_DETROIT]: 'Eastern Time - Detroit',
  [UsTimezone.EASTERN_KENTUCKY_LOUISVILLE]:
    'Eastern Time - Louisville, Kentucky',
  [UsTimezone.EASTERN_KENTUCKY_MONTICELLO]:
    'Eastern Time - Monticello, Kentucky',
  [UsTimezone.EASTERN_INDIANA_INDANAPOLIS]:
    'Eastern Time - Indianapolis, Indiana',
  [UsTimezone.EASTERN_INDIANA_VINCENNES]: 'Eastern Time - Vincennes, Indiana',
  [UsTimezone.EASTERN_INDIANA_WINAMAC]: 'Eastern Time - Winamac, Indiana',
  [UsTimezone.EASTERN_INDIANA_MARENGO]: 'Eastern Time - Marengo, Indiana',
  [UsTimezone.EASTERN_INDIANA_PETERSBURG]: 'Eastern Time - Petersburg, Indiana',
  [UsTimezone.EASTERN_INDIANA_VEVAY]: 'Eastern Time - Vevey, Indiana',
  [UsTimezone.ATLANTIC_PUERTO_RICO]: 'Atlantic Time - Puerto Rico',
  [UsTimezone.ATLANTIC_ST_THOMAS]:
    'Atlantic Time - St. Thomas, U.S. Virgin Islands',
  [UsTimezone.PALAU]: 'Palau Time - Palau',
  [UsTimezone.CHAMORRO_GUAM]: 'Chamorro Time - Guam',
  [UsTimezone.CHAMORRO_NORTHERN_MARIANA_ISLANDS]:
    'Chamorro Time - Saipan, Northern Mariana Islands',
  [UsTimezone.CHUUK]: 'Chuuk Time - Micronesia',
  [UsTimezone.MARSHALL_ISLANDS_MAJURO]: 'Marshall Islands Time - Majuro',
})

// Aliases, e.g. {"America/Detroit": "America/New_York"}
export const TimezoneAlias = Object.freeze(
  momentTimezoneData.links.reduce((acc, curr) => {
    const [canonical, alias] = curr.split('|')
    return {...acc, [alias]: canonical}
  }, {})
)

export const UsState = Object.freeze({
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
})

export const UsTerritoryCountryCodes = [
  CountryCode.PUERTO_RICO,
  CountryCode.US_VIRGIN_ISLANDS,
  CountryCode.AMERICAN_SAMOA,
  CountryCode.NORTHERN_MARIANA_ISLANDS,
  CountryCode.GUAM,
]
// $FlowFixMe: Flow doesn't understand Object.values
export const AcceptedCountryCodes = new Set(Object.values(CountryCode))
/**
 * Mapping from the ISO-3166-1 alpha-2 country code to its display name. Only country codes
 * we suport are represented (currently, only the U.S. and its territories and commonwealths)
 * Keep in sync with lib.geo.COUNTRY_CODES_TO_DISPLAY_NAMES
 */
export const CountryCodeToDisplayName = Object.freeze({
  [CountryCode.UNITED_STATES]: 'United States',
  [CountryCode.PUERTO_RICO]: 'Puerto Rico',
  [CountryCode.US_VIRGIN_ISLANDS]: 'U.S. Virgin Islands',
  [CountryCode.AMERICAN_SAMOA]: 'American Samoa',
  [CountryCode.NORTHERN_MARIANA_ISLANDS]: 'Northern Mariana Islands',
  [CountryCode.GUAM]: 'Guam',
})
export const UsTerritoryCountryCodeToDisplayName = Object.freeze({
  [CountryCode.PUERTO_RICO]: CountryCodeToDisplayName[CountryCode.PUERTO_RICO],
  [CountryCode.US_VIRGIN_ISLANDS]:
    CountryCodeToDisplayName[CountryCode.US_VIRGIN_ISLANDS],
  [CountryCode.AMERICAN_SAMOA]:
    CountryCodeToDisplayName[CountryCode.AMERICAN_SAMOA],
  [CountryCode.NORTHERN_MARIANA_ISLANDS]:
    CountryCodeToDisplayName[CountryCode.NORTHERN_MARIANA_ISLANDS],
  [CountryCode.GUAM]: CountryCodeToDisplayName[CountryCode.GUAM],
})

// All states and territories formatted as <2-letter code>: <display name>. e.g., AK: "Alaska"
export const UsStatesAndTerritories = Object.assign(
  {},
  UsState,
  UsTerritoryCountryCodeToDisplayName
)

// All states formatted as <2-letter code>: <display name>. e.g., AK: "Alaska"
export const UsStates = Object.assign({}, UsState)

/** Keep in sync with api.serializers.PERMITTED_RESOURCES_FOR_UPLOAD */
export const Resource = Object.freeze({
  EVENT: 'event',
  ORGANIZATION: 'organization',
  ORGANIZATION_SOCIAL: 'organization_social',
  ORGANIZATION_DIST_ORG_SOCIAL: 'organization_dist_org_social',
})

export const DashboardOrgSettingsSection = Object.freeze({
  ADDITIONAL_FIELDS: 'additional fields',
  DONATION_SETTINGS: 'donation settings',
  EMBED: 'embed',
  EVENT_CAMPAIGNS: 'event campaigns',
  EVENTS: 'events',
  EXPORTS: 'exports',
  GROUPS: 'groups',
  INTEGRATIONS: 'integrations',
  LANDING: 'landing',
  PROMOTIONS: 'promotions',
  SALESFORCE: 'salesforce',
  SETTINGS: 'settings',
  STATS: 'stats',
  USERS: 'users',
  VAN_SETTINGS: 'van settings',
  VOLUNTEERS: 'volunteers',
  ZOOM: 'zoom',
})

export const DashboardUserSettingsSection = Object.freeze({
  PASSWORD: 'password',
})

/** Keep in sync with notifications.enums.NotificationSetting */
export const NotificationSetting = Object.freeze({
  NONE: 0,
  ALL: 1,
})

export const reduxActions = Object.freeze({
  ADD_ORG_FEED_MEMBERSHIP_AFFIRMATION: 'add-org-feed-membership-affirmation',
  SET_FEED_USER_DATA: 'set-feed-user-data',
  SET_CURRENT_VOLUNTEER: 'set-current-volunteer',
  SET_FEED_RESHIFT_METHOD: 'set-feed-reshift-method',
  SET_FEED_FLAKE_RESHIFT_METHOD: 'set-feed-flake-reshift-method',
  SET_LAST_LOGGED_IN_AT_TO_NOW: 'set-last-logged-in-at-to-now',
  LOAD_DATA_FROM_EMBED: 'load-data-from-embed',
  SET_BUILD_COMMIT: 'set-build-commit',
  SET_NUM_TIMES_VIEWED_EVENT_CREATE_CHAINING:
    'set-num-times-viewed-event-chaining',
  SET_EVENT_SIGNUPS: 'set-event-signups',
})

export const ReferrerBannerVariant = Object.freeze({
  ANONYMOUS: 'anonymous',
  NAME_ONLY: 'name-only',
  NAME_AND_TIME: 'name-and-time',
})

export const EventTag = Object.freeze({
  HIGH_PRIORITY: 'high-priority',
  GOTV: 'gotv',
  VIRTUAL: 'virtual',
  ACT_NOW: 'act-now',
  SOONEST: 'soonest',
  CLOSEST: 'closest',
})

export function eventTagToDisplayName(eventTag: ValuesOf<typeof EventTag>) {
  switch (eventTag) {
    case EventTag.HIGH_PRIORITY:
      return 'Highest Priority'
    case EventTag.GOTV:
      return 'Get Out the Vote!'
    case EventTag.ACT_NOW:
      return 'Pick any time'
    case EventTag.VIRTUAL:
      return 'From anywhere'
    case EventTag.CLOSEST:
      return 'Closest'
    case EventTag.SOONEST:
      return 'Soonest'
    default:
      // "This should never happen" - Jared
      return ''
  }
}

export const EventApprovalStatusToDisplayName = {
  [EventApprovalStatus.APPROVED]: 'Approved',
  [EventApprovalStatus.NEEDS_APPROVAL]: 'Awaiting Review',
  [EventApprovalStatus.REJECTED]: 'Declined',
  [EventApprovalStatus.NEEDS_HOST_VERIFICATION]: 'Needs Host Verification',
}

export const LoginMethod = Object.freeze({
  MAGIC_LINK: 1,
  PASSWORD: 2,
  FACEBOOK: 3,
  GOOGLE: 4,
})

export const AuthProvider = Object.freeze({
  FACEBOOK: 1,
  GOOGLE: 2,
})

export const AuthProviderToDisplayName = Object.freeze({
  [AuthProvider.GOOGLE]: 'Google',
  [AuthProvider.FACEBOOK]: 'Facebook',
})

export const AuthProviderToLoginMethod = Object.freeze({
  [AuthProvider.FACEBOOK]: LoginMethod.FACEBOOK,
  [AuthProvider.GOOGLE]: LoginMethod.GOOGLE,
})

export const AuthProviderConnectionState = {
  NOT_ATTEMPTED: 1,
  FAILED: 2,
  SUCCEEDED: 3,
}

/** Keep in sync with event.enums.DistributedOrganizingCreateSource */
export const DistributedOrganizingCreateSource = Object.freeze({
  PUBLIC: 1,
  DASHBOARD: 2,
})

// Client-only enum for postMessage communication; see screens/FacebookLoginIframeScreen
export const FacebookLoginMessageType = Object.freeze({
  SUCCESS: 1,
  ERROR: 2,
  INITIATED: 3,
})

export const MessageType = Object.freeze({
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  HINT: 'hint',
})

// Keep in sync with event.enums.ACCESSIBILITY_STATUS_DISPLAY_NAMES
// Use this for host/organizer-facing surfaces.
export const AccessibilityStatusToDisplayName = Object.freeze({
  [AccessibilityStatus.ACCESSIBLE]: 'Yes',
  [AccessibilityStatus.NOT_ACCESSIBLE]: 'No',
  [AccessibilityStatus.NOT_SURE]: 'Not sure',
})

/** Keep in sync with messaging.enums.UserInitiatedMessageRecipients */
export const UserInitiatedMessageRecipients = Object.freeze({
  EVENT_OWNERS: 1,
  ATTENDEES: 4,
  CHAT: 5,
  POTENTIAL_HOSTS: 6,
  ALL_PAST_ATTENDEES: 7,
  REPLY_TO_EVENT_HOSTS: 8,
  EVENT_CAMPAIGN_HOSTS: 9,
  EVENT_CAMPAIGN_ATTENDEES: 10,
})

export function eventCampaignStatusToDisplayName(eventCampaignStatus: ValuesOf<typeof EventCampaignStatus>) {
  if (eventCampaignStatus === EventCampaignStatus.INACTIVE) {
    return 'Inactive'
  }
  if (eventCampaignStatus === EventCampaignStatus.ENDED) {
    return 'Ended'
  }
  return 'Active'
}

export const EventChainingAction = Object.freeze({
  CANCELLED: 1,
  CONFIRMED: 2,
  LEFT_POSITIVE_FEEDBACK: 3,
  SIGNED_UP: 4,
  SMS_CANCEL: 5,
  SMS_CANCEL_TEST_WHICH: 6,
  SMS_CANCEL_TEST_ANOTHER_TIME: 7,
  SMS_CANCEL_TEST_WHEN: 8,
  SMS_CANCEL_TEST_WE_COUNT: 9,
  SMS_CANCEL_TEST_CAN_WE_COUNT: 10,
  SMS_CANCEL_TEST_SORRY: 11,
  SMS_CANCEL_TEST_RIGHT_NOW: 12,
  SMS_CANCEL_TEST_TOP_OF_MIND: 13,
  SMS_NO_SHOW: 14,
  SMS_POSITIVE_FEEDBACK: 15,
  // 16-23 are all deprecated test followup modal contexts, but might still exist in the wild, so
  // we still want to be able to recognize them. Eventually we can remove them (probably in concert
  // with removing the related shortlink subtypes that would add these followup_modal_contexts in
  // the first place).
  SMS_POSITIVE_FEEDBACK_TEST_RIGHT_NOW: 16,
  SMS_POSITIVE_FEEDBACK_TEST_CAN_WE_COUNT_ON_YOU: 17,
  SMS_POSITIVE_FEEDBACK_TEST_TOP_OF_MIND: 18,
  SMS_POSITIVE_FEEDBACK_TEST_COMMIT: 19,
  SMS_POSITIVE_FEEDBACK_TEST_WHEN_SEE: 20,
  SMS_POSITIVE_FEEDBACK_TEST_WHEN_JOIN: 21,
  SMS_POSITIVE_FEEDBACK_TEST_WE_COUNT_ON_YOU: 22,
  SMS_POSITIVE_FEEDBACK_TEST_CHECK_OUT: 23,

  SMS_TEST_NO_FEEDBACK_ATTENDED: 24,
  SMS_TEST_NO_FEEDBACK_MISSED: 25,
  SMS_TEST_BEFORE_TIMER: 26,
  SMS_TEST_AFTER_TIMER: 27,
  SMS_TEST_SHORT_FORM_POSITIVE_FEEDBACK: 28,
  SMS_TEST_NO_COMMITMENT: 29,
  SMS_TEST_COMMITTED: 30,
  SMS_TEST_NO_TO_SHIFT: 31,
  SMS_TEST_MORE_EVENTS: 32,
  SMS_SOMETHING_WENT_WRONG_OTHER_EVENTS: 33,
  SMS_SOMETHING_WENT_WRONG_EVENT_DETAILS: 34,

  CHECKED_IN: 35
  // This enum object is a frontend copy of the EventChainingAction enum class in event/enums.py. PLEASE KEEP SYNCED AT ALL TIMES.
  // Next ID: 36
})

export const EVENT_CHAINING_ACTION_TO_ANALYTICS_NAME = invert(
  EventChainingAction
)
export const CANCELLED_CHAINING_ACTION_TYPES = new Set([
  EventChainingAction.CANCELLED,
  EventChainingAction.SMS_CANCEL,
  EventChainingAction.SMS_CANCEL_TEST_WHICH,
  EventChainingAction.SMS_CANCEL_TEST_ANOTHER_TIME,
  EventChainingAction.SMS_CANCEL_TEST_WHEN,
  EventChainingAction.SMS_CANCEL_TEST_WE_COUNT,
  EventChainingAction.SMS_CANCEL_TEST_CAN_WE_COUNT,
  EventChainingAction.SMS_CANCEL_TEST_SORRY,
  EventChainingAction.SMS_CANCEL_TEST_RIGHT_NOW,
  EventChainingAction.SMS_CANCEL_TEST_TOP_OF_MIND,
  EventChainingAction.SMS_NO_SHOW,
])

export const CustomSignupFieldTypeToDisplayName = Object.freeze({
  [CustomSignupFieldType.BOOLEAN]: 'Checkbox',
  [CustomSignupFieldType.SHORT_TEXT]: 'Short answer',
  [CustomSignupFieldType.LONG_TEXT]: 'Long answer',
  [CustomSignupFieldType.DROPDOWN]: 'Dropdown',
})

// keep in sync with shortlink.models.ParticipationShortlinkSubtype
// note that most of these aren't used on the FE (mainly just EVENT_DETAILS_NATIVE_SHARE and
// POST_SIGNUP_NATIVE_SHARE) but we list the other enum members for completeness
export const ParticipationShortlinkSubtype = Object.freeze({
  SMS_CONFIRM_SHARE_SEPARATE_MESSAGE: 'a',
  DEPRECATED__SMS_CONFIRM_SHARE_SAME_MESSAGE: 'b', // Deprecated!
  EVENT_DETAILS_NATIVE_SHARE: 'n',
  POST_SIGNUP_NATIVE_SHARE: 'o',
  SMS_REGISTRATION_SHARE_WITH_TRANSACTIONAL_INSTRUCTIONS: 'p',
  DEPRECATED__SMS_REGISTRATION_SHARE_TRANSACTIONAL_BEFORE: 'q', // Deprecated!
  SMS_REGISTRATION_SHARE: 'r',
  POST_SIGNUP: 's', // Default; implied by absence
})

export const ACT_BLUE_URL_PREFIX = 'https://secure.actblue.com'
export const CLASSY_URL_PREFIX = 'https://www.classy.org/give'
export const EVERYACTION_URL_PREFIX = 'https://secure.everyaction.com'

// Running list of URLs that we know support query param amount prefilling.
// Keep in sync with KnownDonationURL enum in python
export const KNOWN_PREFILL_DONATION_URLS = [
  ACT_BLUE_URL_PREFIX,
  CLASSY_URL_PREFIX,
  EVERYACTION_URL_PREFIX,
]

export const DashboardChainingModal = Object.freeze({
  NON_SHARE: 1,
  SHARE: 2,
})

export const LocaleNameToLanguageDisplayName = Object.freeze({
  [LocaleName.EN]: 'English',
  [LocaleName.ES]: 'Spanish',
})

export const PostSignupAskToDisplayName = Object.freeze({
  [PostSignupAsk.SOCIAL_SHARING]: 'Bring a friend',
  [PostSignupAsk.DONATION]: 'Make a donation',
  [PostSignupAsk.EVENT_SUGGESTIONS]: 'Sign up for other events',
  [PostSignupAsk.SHIFT_SUGGESTIONS]:
    'Sign up for another shift of this same event',
})

export const PostSignupAskToDisplaySubtext = Object.freeze({
  [PostSignupAsk.SOCIAL_SHARING]:
    '14.3% of signups on Mobilize come from "Bring a friend" prompts',
  [PostSignupAsk.DONATION]:
    '20% of supporters on Mobilize start a donation when prompted',
  [PostSignupAsk.EVENT_SUGGESTIONS]:
    '12% of signups on Mobilize come from event suggestions',
  [PostSignupAsk.SHIFT_SUGGESTIONS]:
    'Turn this off for events where you only want people to attend once, like trainings',
})

export const PromotionRequestStatus = Object.freeze({
  REQUESTED: 1,
  APPROVED: 2,
  IGNORED: 3,
})

export const RequestToPromoteStatus = Object.freeze({
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
})

// Keep in sync with event.enums.SharerRole
export const SharerRole = Object.freeze({
  EVENT_OWNER: 1,
  ORGANIZER: 2,
})

// Tracked actions in the self service org creation flow.
export const SelfServiceOrgCreationActions = Object.freeze({
  CLICKED_GOOGLE_LOGIN: 'clickedGoogleLogin',
  CLICKED_FB_LOGIN: 'clickedFbLogin',
  CLICKED_MODAL_LOGIN: 'clickedModalLogin',
  SIGNED_UP_WITH_EMAIL: 'signedUpWithEmail',
  CONTINUED_AS_LOGGED_IN_USER: 'continuedAsLoggedInUser',
  PROCEEDED_TO_SECOND_STEP: 'proceededToSecondStep',
  CLICKED_SUBMIT_ORG_FORM: 'submittedOrgForm',
})

export const VirtualActionOptions = Object.freeze({
  EXTERNAL_LINK: 1,
  PHONE_CALL: 3,
  SEND_EMAIL: 4,
  SOCIAL_SHARE: 5,
  // reserved: 2
  // next id: 6
})

const virtualActionOptionsI18nMessages = defineMessages({
  [VirtualActionOptions.PHONE_CALL]: {
    defaultMessage:
      'Make a call',
  },
  [VirtualActionOptions.SEND_EMAIL]: {
    defaultMessage: 'Send an email',
  },
})

export function getVirtualActionOptionPredefinedMessage (option: ValuesOf<typeof VirtualActionOptions>) {
  return virtualActionOptionsI18nMessages[option] || null
}

export const VirtualActionSocialShareType = Object.freeze({
  FACEBOOK_ONLY: 1,
  TWITTER_ONLY: 2,
  // next id: 3
})

/** Keep in sync with zoom.enums.ZoomMeetingType */
export const ZoomMeetingType = Object.freeze({
  MEETING: 'MEETING',
  WEBINAR: 'WEBINAR',
})

export const excludedOrgTypesForIEQuestions = [
  OrgType.CAMPAIGN,
  OrgType.PARTY_COMMITTEE,
]

/** Keep in sync with organization.enums.OrgSize  **/
export const OrgSize = Object.freeze({
  X_SMALL: 'X_SMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
})

export function orgSizeToDisplayName(orgSize: ValuesOf<typeof OrgSize>, isUnion = false) {
  // Note! Unions only use a subset of all sizes.

  const map = {
    [OrgSize.X_SMALL]: '0-10',
    [OrgSize.SMALL]: isUnion ? '0 to 50,000' : '10-50',
    [OrgSize.MEDIUM]: isUnion ? '50,000 to 200,000' : '50-200',
    [OrgSize.LARGE]: isUnion ? '200,000+' : '200+',
  }
  if (!map[orgSize]) return ''
  return map[orgSize]
}

/** Keep in sync with organization.enums.OrgAnnualRevenue  **/
export const OrgAnnualRevenue = Object.freeze({
  X_SMALL: 'X_SMALL',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
})

export function orgAnnualRevenueToDisplayName(orgAnnualRevenue: ValuesOf<typeof OrgAnnualRevenue>) {
  const map = {
    [OrgAnnualRevenue.X_SMALL]: '$0 to $500,000',
    [OrgAnnualRevenue.SMALL]: '$500,000 to $2 million',
    [OrgAnnualRevenue.MEDIUM]: '$2 million to $50 million',
    [OrgAnnualRevenue.LARGE]: '$50 million+',
  }
  if (!map[orgAnnualRevenue]) return ''
  return map[orgAnnualRevenue]
}

/** Keep in sync with organization.enums.OrgCreatorDepartment  **/
export const OrgCreatorDepartment = Object.freeze({
  EXECUTIVE: 'EXECUTIVE',
  ADVOCACY: 'ADVOCACY',
  DEVELOPMENT: 'DEVELOPMENT',
  ORGANIZING: 'ORGANIZING',
  MEMBER: 'MEMBER',
  POLITICAL: 'POLITICAL',
  COMMUNICATIONS: 'COMMUNICATIONS',
  OPERATIONS: 'OPERATIONS',
  DATA: 'DATA',
  OTHER: 'OTHER',
})

export function orgCreatorDepartmentToDisplayName(orgCreatorDepartment: ValuesOf<typeof OrgCreatorDepartment>) {
  const map = {
    [OrgCreatorDepartment.EXECUTIVE]: 'Executive/Leadership',
    [OrgCreatorDepartment.ADVOCACY]: 'Advocacy',
    [OrgCreatorDepartment.DEVELOPMENT]: 'Development',
    [OrgCreatorDepartment.ORGANIZING]: 'Organizing',
    [OrgCreatorDepartment.MEMBER]: 'Member/Supporter Engagement',
    [OrgCreatorDepartment.POLITICAL]: 'Political',
    [OrgCreatorDepartment.COMMUNICATIONS]: 'Communications/Marketing',
    [OrgCreatorDepartment.OPERATIONS]: 'Operations',
    [OrgCreatorDepartment.DATA]: 'Data',
    [OrgCreatorDepartment.OTHER]: 'Other',
  }
  if (!map[orgCreatorDepartment]) return ''
  return map[orgCreatorDepartment]
}

/** Keep in sync with fundraising.enums.Currency  **/
export const Currency = Object.freeze({
  USD: 1,
})

/** Keep in sync with fundraising.enums.PaymentMethod  **/
export const PaymentMethod = Object.freeze({
  CREDIT_CARD: 1,
  PAYPAL: 2,
  APPLE_PAY: 3,
  EFT: 4,
})

export const OnlineActionPaymentMethodToPaymentMethod = Object.freeze({
  creditcard: PaymentMethod.CREDIT_CARD,
  paypal: PaymentMethod.PAYPAL,
})

/**
 * Keep in sync with fundraising.enums.PaymentFrequency
 * and
 * ssr/src/util/types.js
 **/
export const PaymentFrequency = Object.freeze({
  ONE_TIME: 1,
  WEEKLY: 2,
  EVERY_TWO_WEEKS: 3,
  EVERY_FOUR_WEEKS: 4,
  MONTHLY: 5,
  QUARTERLY: 6,
  YEARLY: 7,
  TWICE_A_YEAR: 8,
})

export function paymentFrequencyToDisplayName(paymentFrequency: ValuesOf<typeof PaymentFrequency>) {
  const map = {
    [PaymentFrequency.ONE_TIME]: 'one-time',
    [PaymentFrequency.WEEKLY]: 'weekly',
    [PaymentFrequency.EVERY_TWO_WEEKS]: 'every two weeks',
    [PaymentFrequency.EVERY_FOUR_WEEKS]: 'every four weeks',
    [PaymentFrequency.MONTHLY]: 'monthly',
    [PaymentFrequency.QUARTERLY]: 'quarterly',
    [PaymentFrequency.YEARLY]: 'yearly',
    [PaymentFrequency.TWICE_A_YEAR]: 'twice a year',
  }
  if (!map[paymentFrequency]) return ''
  return map[paymentFrequency]
}

// Defined in table row "Recurring Commitment Frequency"
// https://docs.everyaction.com/reference/query-strings
export const OnlineActionSelectedFrequency = Object.freeze({
  ONE_TIME: '0',
  WEEKLY: '1',
  EVERY_TWO_WEEKS: '2',
  EVERY_FOUR_WEEKS: '3',
  MONTHLY: '4',
  QUARTERLY: '5',
  YEARLY: '6',
  TWICE_A_YEAR: '7',
})

export const OnlineActionSelectedFrequencyToPaymentFrequency = Object.freeze({
  [OnlineActionSelectedFrequency.ONE_TIME]: PaymentFrequency.ONE_TIME,
  [OnlineActionSelectedFrequency.WEEKLY]: PaymentFrequency.WEEKLY,
  [OnlineActionSelectedFrequency.EVERY_TWO_WEEKS]:
    PaymentFrequency.EVERY_TWO_WEEKS,
  [OnlineActionSelectedFrequency.EVERY_FOUR_WEEKS]:
    PaymentFrequency.EVERY_FOUR_WEEKS,
  [OnlineActionSelectedFrequency.MONTHLY]: PaymentFrequency.MONTHLY,
  [OnlineActionSelectedFrequency.QUARTERLY]: PaymentFrequency.QUARTERLY,
  [OnlineActionSelectedFrequency.YEARLY]: PaymentFrequency.YEARLY,
  [OnlineActionSelectedFrequency.TWICE_A_YEAR]: PaymentFrequency.TWICE_A_YEAR,
})

// These are the OnlineAction form values before conversion to Mobilize enums.
// TODO: Internationalize the display names.
export const OnlineActionSelectedFrequencyToDisplayName = Object.freeze({
  [OnlineActionSelectedFrequency.ONE_TIME]: 'one-time',
  [OnlineActionSelectedFrequency.WEEKLY]: 'weekly',
  [OnlineActionSelectedFrequency.EVERY_TWO_WEEKS]: 'every two weeks',
  [OnlineActionSelectedFrequency.EVERY_FOUR_WEEKS]: 'every four weeks',
  [OnlineActionSelectedFrequency.MONTHLY]: 'monthly',
  [OnlineActionSelectedFrequency.QUARTERLY]: 'quarterly',
  [OnlineActionSelectedFrequency.YEARLY]: 'yearly',
  [OnlineActionSelectedFrequency.TWICE_A_YEAR]: 'twice a year',
})

export const OnlineActionFillSource = Object.freeze({
  DEFAULTS: 1,
  FAST_ACTION: 2,
  FAST_ACTION_CREDIT_CARD_ONLY: 3,
  MOBILIZE: 4, // From the Mobilize identity
  PROFILE: 5, // The ActionProfile DataBag
})

export const SignupStep = Object.freeze({
  PAYMENT: 'payment',
  SIGNUP: 'signup',
})

export const UsageStatType = Object.freeze({
  USERS: 'users',
  EVENTS: 'events',
  SIGNUPS: 'signups',
})

export const UsageStatTypeToStarterLimit = Object.freeze({
  [UsageStatType.USERS]: 5,
  [UsageStatType.EVENTS]: 5,
  [UsageStatType.SIGNUPS]: 50,
})

/** Keep in sync with organization.enums.PromotionContext */
export const PromotionContext = Object.freeze({
  DASHBOARD_TABLE: 'DASHBOARD_TABLE',
  PROMOTION_REQUEST: 'PROMOTION_REQUEST',
  SUGGESTIONS_MODAL: 'SUGGESTIONS_MODAL',
  SINGLE_EVENT_PROMOTION_SUGGESTIONS_MODAL:
    'SINGLE_EVENT_PROMOTION_SUGGESTIONS_MODAL',
  AUTO_CREATED: 'AUTO_CREATED',
  SELF_SERVICE_PROMOTION_ONLY: 'SELF_SERVICE_PROMOTION_ONLY',
  SINGLE_EVENT_PROMOTION: 'SINGLE_EVENT_PROMOTION',
  POST_PROMOTED_EMAIL: 'POST_PROMOTED_EMAIL',
  DASHBOARD_SUGGESTIONS_TRENDING_EVENTS:
    'DASHBOARD_SUGGESTIONS_TRENDING_EVENTS',
  DASHBOARD_SUGGESTIONS_SIMILAR_ORGS: 'DASHBOARD_SUGGESTIONS_SIMILAR_ORGS',
})

/** Keep in sync with mail.enums.EmailCategory  **/
export const EmailCategory = Object.freeze({
  EVENT: 1,
  ACCOUNT_CRITICAL: 2,
  PROMOTION: 3,
  DASHBOARD_JOB: 4,
  ORGANIZER_SIGNUP_NOTIFICATION: 5,
  EVENT_REMINDER: 6,
  DASHBOARD_CSV_READY: 8,
  POST_SHIFT: 9,
  DISTRIBUTED_ORGANIZING_FOR_HOSTS: 10,
  DISTRIBUTED_ORGANIZING_FOR_ORG_STAFF: 11,
  USER_INITIATED_MESSAGING_TO_ATTENDEES: 12,
  USER_INITIATED_MESSAGING_ADMINISTRATIVE: 13,
  USER_INITIATED_MESSAGING_TO_EVENT_OWNERS: 14,
  USER_INITIATED_MESSAGING_CHAT: 16,
  CONTENT_REPORTS: 15,
  ORGANIZER_NUDGES: 17,
  PROMOTION_REQUEST: 18,
  ORG_NEWSLETTER: 19,
  USER_INITIATED_MESSAGING_CHAT_TO_HOSTS: 20,
  SAME_HOST_NEW_EVENT: 21,
  TIMESLOT_NEAR_COMPLETION: 22,
  USER_INITIATED_MESSAGING_TO_POTENTIAL_HOSTS: 23,
  POST_SHIFT_DIGEST: 24,
  NEWSLETTER_CUSTOMIZATION_NUDGE: 25,
  CO_HOST_NOTIFICATION: 26,
  USER_INITIATED_MESSAGING_TO_ALL_PAST_ATTENDEES: 27,
  CHAINED_EVENT_INVITE: 28,
  USER_INITIATED_MESSAGING_REPLY_TO_EVENT_HOSTS: 29,
  NONEXCLUSIVE_USER_NEWSLETTER: 30,
  HOST_THANK_YOU: 31,
  ACCOUNT_DIGEST: 32,
  ACCOUNT_EXCEEDING_USAGE: 33,
  INTERNAL_ONLY: 34,
  USER_INITIATED_MESSAGING_TO_EVENT_CAMPAIGN_HOSTS: 35,
  USER_INITIATED_MESSAGING_TO_EVENT_CAMPAIGN_ATTENDEES: 36,
})

export function emailCategoryToUnsubscribeOutcome(emailCategory: ValuesOf<typeof EmailCategory>) {
  const intl = createIntl()

  const map = defineMessages({
    [EmailCategory.EVENT]: {
      defaultMessage:
        'You have been unsubscribed from event confirmation emails',
    },
    [EmailCategory.EVENT_REMINDER]: {
      defaultMessage:
        'You have been unsubscribed from future event reminder emails',
    },
    [EmailCategory.POST_SHIFT]: {
      defaultMessage: 'You have been unsubscribed from event-followup emails',
    },
    [EmailCategory.USER_INITIATED_MESSAGING_TO_ALL_PAST_ATTENDEES]: {
      defaultMessage:
        'You have been unsubscribed from messages from event hosts',
    },
    [EmailCategory.USER_INITIATED_MESSAGING_TO_ATTENDEES]: {
      defaultMessage:
        'You have been unsubscribed from messages from event hosts',
    },
  })
  if (!map[emailCategory]) return ''
  return intl?.formatMessage(map[emailCategory])
}

export function emailCategoryToSubtext(emailCategory: ValuesOf<typeof EmailCategory>) {
  const intl = createIntl()

  const map = defineMessages({
    [EmailCategory.EVENT]: {
      defaultMessage:
        'Includes critical information like location and links to join the event',
    },
    [EmailCategory.EVENT_REMINDER]: {
      defaultMessage:
        'Includes critical information like location and links to join the event',
    },
    [EmailCategory.PROMOTION_REQUEST]: {
      defaultMessage:
        'We recommend that at least one admin leave this notification on',
    },
  })
  if (!map[emailCategory]) return ''
  return intl?.formatMessage(map[emailCategory])
}

export function emailCategoryToSummary(emailCategory: ValuesOf<typeof EmailCategory>) {
  const intl = createIntl()

  const map = defineMessages({
    [EmailCategory.EVENT]: {
      defaultMessage:
        'Event confirmations include critical information like conference links and messages from event hosts.',
    },
    [EmailCategory.EVENT_REMINDER]: {
      defaultMessage:
        'Event reminders include critical information like conference links and messages from event hosts.',
    },
    [EmailCategory.POST_SHIFT]: {
      defaultMessage:
        'You will no longer receive follow-up messages after your events.',
    },
    [EmailCategory.USER_INITIATED_MESSAGING_TO_ALL_PAST_ATTENDEES]: {
      defaultMessage:
        'You will no longer receive direct messages from event hosts.',
    },
    [EmailCategory.USER_INITIATED_MESSAGING_TO_ATTENDEES]: {
      defaultMessage:
        'You will no longer receive direct messages from event hosts.',
    },
    [EmailCategory.PROMOTION]: {
      defaultMessage:
        'You will no longer receive messages when an organization you promote creates an event.',
    },
    [EmailCategory.PROMOTION_REQUEST]: {
      defaultMessage:
        'You will no longer receive notifications when other organizations want you to promote them.',
    },
    [EmailCategory.ORGANIZER_SIGNUP_NOTIFICATION]: {
      defaultMessage:
        'You will no longer receive messages when someone signs up for your events.',
    },
    [EmailCategory.DASHBOARD_JOB]: {
      defaultMessage:
        'You will no longer receive messages when a dashboard sync is started or completed.',
    },
    [EmailCategory.DISTRIBUTED_ORGANIZING_FOR_ORG_STAFF]: {
      defaultMessage:
        'You will no longer receive messages when hosts submit or edit events.',
    },
    [EmailCategory.ORGANIZER_NUDGES]: {
      defaultMessage:
        'You will no longer receive reminder messages the day before and day of your event.',
    },
    [EmailCategory.POST_SHIFT_DIGEST]: {
      defaultMessage:
        'You will no longer receive recap messages after your events.',
    },
    [EmailCategory.ACCOUNT_DIGEST]: {
      defaultMessage: 'You will no longer receive monthly dashboard recaps.',
    },
    [EmailCategory.NEWSLETTER_CUSTOMIZATION_NUDGE]: {
      defaultMessage:
        "You will no longer receive reminders about customizing your organization's automated weekly newsletter.",
    },
    [EmailCategory.TIMESLOT_NEAR_COMPLETION]: {
      defaultMessage:
        'You will no longer receive messages when your event is nearly over.',
    },
    [EmailCategory.CO_HOST_NOTIFICATION]: {
      defaultMessage:
        'You will no longer receive messages when you are added to an event as a co-host.',
    },
  })
  if (!map[emailCategory]) return ''
  return intl?.formatMessage(map[emailCategory])
}

export const eventAttendanceEmailCategories = [
  EmailCategory.EVENT,
  EmailCategory.EVENT_REMINDER,
  EmailCategory.POST_SHIFT,
]

export const eventsOwnershipEmailCategories = [
  EmailCategory.ORGANIZER_SIGNUP_NOTIFICATION,
  EmailCategory.ORGANIZER_NUDGES,
  EmailCategory.POST_SHIFT_DIGEST,
  EmailCategory.TIMESLOT_NEAR_COMPLETION,
  EmailCategory.CO_HOST_NOTIFICATION,
]

export const dashboardEmailCategories = [
  EmailCategory.PROMOTION,
  EmailCategory.PROMOTION_REQUEST,
  EmailCategory.DASHBOARD_JOB,
  EmailCategory.DISTRIBUTED_ORGANIZING_FOR_ORG_STAFF,
  EmailCategory.ACCOUNT_DIGEST,
  EmailCategory.NEWSLETTER_CUSTOMIZATION_NUDGE,
]

export const ACCESSIBILITY_FEATURE_VALUES = new Set(
  Object.keys(AccessibilityFeature).map((af) => AccessibilityFeature[af])
)

export function getAccessibilityFeatureDisplayName(accessibilityFeature: ValuesOf<typeof AccessibilityFeature>) {
  const intl = createIntl()

  const messages = defineMessages({
    [AccessibilityFeature.ACCESSIBLE_RESTROOMS]: {
      defaultMessage: 'Accessible restrooms',
    },
    [AccessibilityFeature.DEDICATED_PARKING_SPOTS]: {
      defaultMessage: 'Dedicated parking spots',
    },
    [AccessibilityFeature.MAINLY_FLAT_GROUND]: {
      defaultMessage: 'Mainly flat ground',
    },
    [AccessibilityFeature.WHEELCHAIR_RAMP]: {defaultMessage: 'Wheelchair ramp'},
    [AccessibilityFeature.NO_STAIRS_OR_STEPS]: {
      defaultMessage: 'No stairs or steps',
    },
    [AccessibilityFeature.WIDE_DOORWAYS_AND_WALKWAYS]: {
      defaultMessage: 'Wide doorways and walkways',
    },
    [AccessibilityFeature.DEDICATED_SEATING]: {
      defaultMessage: 'Dedicated seating',
    },
    [AccessibilityFeature.LIVE_CAPTIONING]: {defaultMessage: 'Live captioning'},
    [AccessibilityFeature.AUDIO_DESCRIPTIONS_FOR_VIDEO]: {
      defaultMessage: 'Audio descriptions for video',
    },
    [AccessibilityFeature.ASL_INTERPRETATION]: {
      defaultMessage: 'ASL interpretation',
    },
    [AccessibilityFeature.SPANISH_INTERPRETATION]: {
      defaultMessage: 'Spanish interpretation',
    },
    [AccessibilityFeature.COVID_SAFETY_PROTOCOLS]: {
      defaultMessage: 'COVID-19 safety protocols',
    },
  })
  if (!messages[accessibilityFeature]) return ''
  return intl?.formatMessage(messages[accessibilityFeature])
}

export const ExportType = Object.freeze({
  SHIFTS_AND_FEEDBACK: 1,
  VOLUNTEERS: 2,
  USERS: 3,
  EVENTS: 4,
})

export const LocationSpecificity = Object.freeze({
  NO_LOCATION: 'no_location',
  EXACT: 'exact',
  ZIPCODE: 'zipcode',
  APPROXIMATE: 'approximate',
  ORG_LOCATION: 'org_location',
})

export const VolunteerScheduleDeepLinkType = Object.freeze({
  MANAGE_GROUP: 'manage_group',
  VIEW_GROUP_MEMBERS: 'view_group_members',
  ADD_GROUP_MEMBERS: 'add_group_members',
  EDIT_GROUP_SIZE: 'edit_group_size',
})

export const ShiftAttributionType = Object.freeze({
  PROMOTION: 1,
  BRING_A_FRIEND: 2,
  ORGANIZER_SHARE_LINK: 3,
  MOBILIZE: 4,
  MOBILIZE_NEWSLETTER: 5,
  MOBILIZE_EVENT_SUGGESTION: 6,
  ORGANIZATION_NEWSLETTER: 7,
})

export const shiftAttributionTypeToDisplayName = Object.freeze({
  [ShiftAttributionType.PROMOTION]: 'Promotion',
  [ShiftAttributionType.BRING_A_FRIEND]: 'Bring-A-Friend',
  [ShiftAttributionType.ORGANIZER_SHARE_LINK]: 'Shared by organizer',
  [ShiftAttributionType.MOBILIZE]: 'Mobilize.us',
  [ShiftAttributionType.MOBILIZE_NEWSLETTER]: 'Mobilize.us Newsletter',
  [ShiftAttributionType.MOBILIZE_EVENT_SUGGESTION]: 'Mobilize Event Suggestion',
  [ShiftAttributionType.ORGANIZATION_NEWSLETTER]: 'Organization Newsletter',
})

export function shiftAttributionToHelperText(shiftAttribution: ValuesOf<typeof ShiftAttributionType>) {
  const intl = createIntl()

  const map = defineMessages({
    [ShiftAttributionType.PROMOTION]: {
      defaultMessage: "Promoted by ",
    },
    [ShiftAttributionType.BRING_A_FRIEND]: {
      defaultMessage: "Recruited by supporter ",
    },
    [ShiftAttributionType.ORGANIZER_SHARE_LINK]: {
      defaultMessage: "Recruited by organizer ",
    },
    [ShiftAttributionType.MOBILIZE]: {
      defaultMessage: "Signed up via the Mobilize.us homepage",
    },
    [ShiftAttributionType.MOBILIZE_NEWSLETTER]: {
      defaultMessage: "Suggested in Mobilize.us weekly newsletter",
    },
    [ShiftAttributionType.MOBILIZE_EVENT_SUGGESTION]: {
      defaultMessage: "Suggested to attendee by Mobilize",
    },
    [ShiftAttributionType.ORGANIZATION_NEWSLETTER]: {
      defaultMessage: "Suggested by your organization's weekly newsletter",
    },
  })
  if (!map[shiftAttribution]) return ''
  return intl?.formatMessage(map[shiftAttribution])
}
// When adding new options make sure all are handled by GoogleLoginButton.js
export const GoogleLoginButtonOptions = Object.freeze({
  LOGIN: 'login',
  CONNECT: 'connect',
  LOGIN_WITH_AHA: 'login_with_aha',
})


