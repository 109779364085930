//
import {
  Button,
  Form,
  Image,
  Input,
  Message,
  MessageType,
  Typography,
} from 'components'

import {F} from 'util/i18n'
import analytics from 'analytics'
import api from 'data/api'
import {omit} from 'util/common'
import styled from '@emotion/styled/macro'
import {useState} from 'react'

const RowWrapper = styled.div`
  display: flex;

  ${(props) =>
    !props.isNameField &&
    `@media (min-width: 768px) {
    flex-direction: row;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }`}
  flex-direction: row;
`
const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const InputWrapper = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`
const ContentWrapper = styled.div`
  @media (min-width: 768px) {
    background: url('/static/lead-capture-image.png');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
  }
  max-width: 1728px;
  display: flex;
  margin: auto;
  flex-direction: column;
`

const CopyWrapper = styled.div`
  padding-left: 4rem;
  padding-top: 2.5rem;
  padding-right: 4rem;
  padding-bottom: 2.5rem;
  @media (min-width: 768px) {
    width: 40%;
  }
  background: rgb(255 255 255 / 65%);
`
const ImageWrapper = styled.div`
  @media (max-width: 768px) {
    content-visibility: visible;
  }
  @media (min-width: 769px) {
    content-visibility: hidden;
  }
`
export default function LeadCaptureFormV2({
  organization,
  onSubmitAction,
  parentComponent,
}) {
  const [email, setEmail] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [errorFields, setErrorFields] = useState(Object)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [success, setSuccess] = useState(false)
  const onSubmit = async () => {
    setIsSubmitting(true)
    setError(false)
    setErrorFields({})

    let response
    try {
      response = await api.submitOrgLead(organization.slug, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        zipcode: zipcode,
      })
    } catch (error) {
      setError(true)
      setErrorFields(error.json && omit(error.json.error, 'message'))
      setIsSubmitting(false)
      return
    }
    response.user_id &&
      analytics.maybeIdentifyUser({id: response.user_id, email: response.email})
    analytics.trackLeadCaptured(parentComponent, organization)

    if (onSubmitAction) {
      onSubmitAction()
    } else {
      setSuccess(true)
    }
  }
  return (
    <ContentWrapper>
      {success ? (
        <CopyWrapper>
          <Typography variant="body1">
            Thanks! You'll be notified when new events are available.
          </Typography>
        </CopyWrapper>
      ) : (
        <CopyWrapper>
          <Form onSubmit={() => onSubmit()}>
            <Typography variant="h4" uppercase>
              {' '}
              Join thousands of other Subscribers
            </Typography>
            <Typography variant="h1"> Be part of a community.</Typography>
            <Typography variant="body1">
              Get weekly updates on upcoming events based on what you've signed
              up for. We'll also slip in a few new events we think you might be
              interested in. Unsubscribe at any time.
            </Typography>
            <FieldsWrapper>
              <RowWrapper isNameField={true}>
                <InputWrapper>
                  <Input
                    label="First Name"
                    required
                    type="text"
                    value={firstName}
                    name="firstName"
                    onChange={({currentTarget: {value}}) => setFirstName(value)}
                    error={!!errorFields.first_name}
                  />
                </InputWrapper>
                <Input
                  label="Last Name"
                  required
                  type="text"
                  value={lastName}
                  name="lastName"
                  onChange={({currentTarget: {value}}) => setLastName(value)}
                  error={!!errorFields.last_name}
                />
              </RowWrapper>
              <RowWrapper>
                <InputWrapper>
                  <Input
                    label={<F defaultMessage="Email Address" />}
                    name="email"
                    type="email"
                    value={email}
                    onChange={({currentTarget: {value}}) =>
                      setEmail(value.trim())
                    }
                    error={!!errorFields.email}
                    required
                  />
                </InputWrapper>

                <Input
                  label="Zipcode"
                  required
                  isZIPCode
                  value={zipcode}
                  onChange={({currentTarget: {value}}) => setZipcode(value)}
                  name="zip"
                  error={!!errorFields.zipcode}
                />
              </RowWrapper>
            </FieldsWrapper>
            {error && (
              <Message
                type={MessageType.ERROR}
                header="Signup failed."
                // Flow thinks Object.values returns mixed even though we know it's
                // Array<string> in this case
                // $FlowFixMe (jared)
                list={Object.values(errorFields).reduce(
                  (acc, curr) => [...acc, ...curr],
                  []
                )}
              />
            )}
            <Button type="submit" disabled={isSubmitting}>
              Stay Connected!
            </Button>
          </Form>
        </CopyWrapper>
      )}
      <ImageWrapper>
        <Image src="/static/lead-capture-image.png" skipProxyMapping={true} />
      </ImageWrapper>
    </ContentWrapper>
  )
}
