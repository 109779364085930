import {groupBy, memoize, sample} from 'util/common'
import {useEffect, useState} from 'react'

import EventChainingTimeslotRow from './EventChainingTimeslotRow'
import {F} from 'util/i18n'
import {Typography} from 'components'
import analytics from 'analytics'
import moment from 'vendor/moment'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const HeadingWrapper = styled.div`
  margin-bottom: ${styles.space.l};
`

// memoize the sample function so as to not re-randomize between component renders. only exported to
// clear memo between tests
export const memoizedSampleById = memoize(sample, (timeslots) =>
  // set the memo key to a list of the timeslot ids it was called with
  timeslots.map(({id}) => id).join(',')
)

function groupByDay(timeslots) {
  return groupBy(timeslots, (timeslot) =>
    moment(timeslot.starts_at_utc, moment.ISO_8601)
      .tz(timeslot.event.timezone)
      .format(moment.HTML5_FMT.DATE)
  )
}

export default function EventSignupChaining({
  isOpen,
  timeslots,
  action,
  organization,
  trackingParams,
  signupIdentityFields,
  onSignup,
  headerPreamble,
  additionalNotification,
}) {
  const distinctDayCount = Object.keys(groupByDay(timeslots)).length

  const count = timeslots.length
  const virtualCount = timeslots.filter((ts) => ts.event.is_virtual).length
  const inPersonCount = count - virtualCount
  // TODO(jared) move stuff referencing the modal up a level (to EventSignupChainingModal and
  // ChainingModalStepper)
  useEffect(() => {
    if (isOpen) {
      analytics.trackFollowupModalShown({
        organization,
        count,
        virtualCount,
        inPersonCount,
        distinctDayCount,
        action,
      })
    }
  }, [
    isOpen,
    count,
    virtualCount,
    inPersonCount,
    action,
    organization,
    distinctDayCount,
  ])

  const [signedUpForTimeslotIds, setSignedUpForTimeslotIds] = useState(
    new Set()
  )

  function getTitle() {
    // Only ask for someone to sign up for 3 timeslots max. (We use Math.min here to prevent
    // asking someone to sign up for more timeslots than there are available in the dialog, but
    // also to cap the ask at 3 if we have more than 3 timeslots to display in the dialog.)
    const totalDesiredSignupCount = Math.min(3, count)
    const additionalSignupCount =
      totalDesiredSignupCount - signedUpForTimeslotIds.size

    // Signed up for all available timeslots
    if (signedUpForTimeslotIds.size === count) {
      return <F defaultMessage="Thanks! See you there!" />
    }
    // Signed up as many timeslots as we ask, but there are still options
    if (additionalSignupCount <= 0) {
      return <F defaultMessage="Thanks! A few more?" />
    }
    // Has not yet signed up for however many timeslots we asked them to
    return (
      <F
        defaultMessage="Sign up for {numOfOtherEvents, plural, one {# more event} other {# more events}}."
        values={{numOfOtherEvents: additionalSignupCount}}
      />
    )
  }

  return (
    <div>
      <HeadingWrapper>
        {headerPreamble && (
          <>
            <Typography variant="h1" center>
              {headerPreamble}
            </Typography>
            <Typography variant="h2" center style={{margin: styles.space.s}}>
              {getTitle()}
            </Typography>
          </>
        )}
        {!headerPreamble && (
          <Typography variant="h1" center>
            {getTitle()}
          </Typography>
        )}
      </HeadingWrapper>
      {additionalNotification && (
        <Typography variant="body1" center>
          {additionalNotification}
        </Typography>
      )}
      {timeslots.map((timeslot) => (
        <EventChainingTimeslotRow
          action={action}
          key={timeslot.id}
          onSignup={onSignup}
          signedUpForTimeslotIds={signedUpForTimeslotIds}
          signupIdentityFields={signupIdentityFields}
          setSignedUpForTimeslotIds={setSignedUpForTimeslotIds}
          timeslot={timeslot}
          trackingParams={trackingParams}
        />
      ))}
    </div>
  )
}
