import {
  Card,
  Form,
  Input,
  Link,
  Message,
  MessageType,
  Typography,
} from 'components'
import {
  Container,
  shouldPrecheckTwoTimeslots,
  shouldShowActLaterButton,
  shouldShowActNowButton,
} from './util'
import CustomFields, {customFieldsValueMapper} from './CustomFields'
import {getAvailableTimeslots, getShifts} from 'events/details/util'
import {isDonationCampaignEvent, isPromptForDonationEvent} from 'util/event'
import {omit, pick} from 'util/common'
import {setCurrentVolunteer, setEventSignups} from 'redux/actions'
import styles, {fontSizeSmall} from 'components/styles'
import submitSignupForm, {validateForm} from './submitSignupForm'
import {useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ActionButtons from './ActionButtons'
import DonationProgress from 'events/details/FundraisingForm/DonationProgress'
import EventClosedBox from 'events/components/EventClosedBox'
import {F} from 'util/i18n'
import IntlMessageContext from 'app/IntlMessageContext'
import PostModals from './PostModals'
import PoweredByMobilize from 'events/components/PoweredByMobilize'
import SMSOptIn from './SMSOptIn'
import {STREET_ADDRESS_FIELDS} from 'app/constants'
import Sticky from './Sticky'
import SupporterProgress from './SupporterProgress'
import TermsOfService from 'events/components/TermsOfService'
import Timeslots from './Timeslots'
import UserSignupFormFields from 'events/components/UserSignupFormFields'
import {formatTimeslot} from 'util/timeslot'
import {getEventTypeAffinityChecks} from 'util/event'
import {getIdentityFieldsFromFormValues} from 'util/user'
import {getOrganizationFeedUrl} from 'util/routing'
import {mapShiftToTimeslot} from '../../../util/timeslot'
import {maybeStringToMaybeInt} from 'util/string'
import {orgFlagIsActive} from 'util/organization'
import styled from '@emotion/styled/macro'
import useIdentityFields from 'hooks/identity-fields'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'

const CustomSignupSubtext = styled.div`
  color: ${styles.colors.neutral400};
  ${fontSizeSmall};
  padding-left: ${styles.space.xs};
  padding-right: ${styles.space.xs};
`

const SelfCheckInTimeslotWrapper = styled.div`
  margin-bottom: ${styles.space.m};
`

const determineFormHeaderMessage = ({
  includeOrgName,
  orgName,
  isAdvocacy,
  isPetition,
  isGroup,
  isSelfCheckIn,
}) => {
  if (isAdvocacy && includeOrgName) {
    return (
      <F
        defaultMessage="Sign up with {orgName} and we'll call to connect you to your representatives"
        values={{orgName}}
      />
    )
  } else if (isAdvocacy && !includeOrgName) {
    return (
      <F defaultMessage="Sign up now and we'll call to connect you to your representatives" />
    )
  } else if (isPetition && includeOrgName) {
    return (
      <F defaultMessage="Sign this {orgName} petition" values={{orgName}} />
    )
  } else if (isPetition && !includeOrgName) {
    return <F defaultMessage="Sign this petition" />
  } else if (isGroup && includeOrgName) {
    return <F defaultMessage="Join this {orgName} group" values={{orgName}} />
  } else if (isGroup && !includeOrgName) {
    return <F defaultMessage="Join this group" />
  } else if (isSelfCheckIn && includeOrgName) {
    return <F defaultMessage="Check in to {orgName}" values={{orgName}} />
  } else if (isSelfCheckIn && !includeOrgName) {
    return <F defaultMessage="Check in now" />
  } else if (includeOrgName) {
    return <F defaultMessage="Sign up with {orgName}" values={{orgName}} />
  }
  return <F defaultMessage="Sign up now" />
}

export default function SignupForm({
  event,
  initialQueryParams,
  maybePrioritizedTimeslot,
  onSuccess,
  organization,
  participationShortlink,
  shareParamsFromSignup,
  trackingParams,
  filterParams,
  eventSuggestionContext,
  owningGroups,
  checkInStartTime,
  isSelfCheckIn,
  displayEventAsPassed,
  displayEventAsFull,
}) {
  const {
    intlMessageConfig: {locale},
  } = useContext(IntlMessageContext)
  const location = useLocation()
  const [customSignupFieldValues, setCustomSignupFieldValue] = useState(
    customFieldsValueMapper(event)
  )
  const [numAdditionalReservations, setNumAdditionalReservations] = useState(
    null
  )
  const [errorFields, setErrorFields] = useState({})
  const [expandActLater, setExpandActLater] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [shiftErrors, setShiftErrors] = useState({})
  const dispatch = useDispatch()
  const currentVolunteer = useSelector((state) => state.currentVolunteer)
  const storedSignedUpTimeslots = useSelector(
    (state) => state.eventSignups[event.id]
  )

  const selfCheckInTimeslot = isSelfCheckIn
    ? mapShiftToTimeslot(event.times, initialQueryParams.check_in_timeslot)
    : null
  let availableTimeslots
  let prioritizedTimeslots
  if (isSelfCheckIn && selfCheckInTimeslot !== null) {
    availableTimeslots = [selfCheckInTimeslot]
    prioritizedTimeslots = [{timeslot_id: selfCheckInTimeslot.id}]
  } else {
    availableTimeslots = getAvailableTimeslots(event)
    prioritizedTimeslots = getShifts(
      maybePrioritizedTimeslot,
      organization,
      event
    )
  }
  const [shifts, setShifts] = useState(prioritizedTimeslots)

  const [
    signupCreatedWithinLastFiveSeconds,
    setSignupCreatedWithinLastFiveSeconds,
  ] = useState(false)
  const [signupInFlightOrCreated, setSignupInFlightOrCreated] = useState(false)
  const [signupResponse, setSignupResponse] = useState({
    recommendedAdditionalTimeslots: [],
    shareShortlink: null,
    groupShortlink: null,
    timeslotIdForShare: null,
    userId: null,
    virtualActionRedirectURL: null,
  })
  const initialIdentityFields = useIdentityFields()
  const [values, setValues] = useState({
    // Exclude the street address fields because we do not support them in the
    // regular signup form and should not overwrite existing identity values
    // with blank values by default.
    ...omit(initialIdentityFields, STREET_ADDRESS_FIELDS),
    smsOptIn: false,
  })
  const identityFields = getIdentityFieldsFromFormValues(values)
  const {
    isAdvocacy,
    isPetition,
    isGroup,
    isRegistrationOnly,
  } = getEventTypeAffinityChecks(event)

  const hasOnlyOneAvailableTimeslot = availableTimeslots.length === 1
  const [signupButtonsInView, setSignupButtonsInView] = useState(true)

  const [selectAllOn, setSelectAllOn] = useState(false)
  const showSelectAllButton =
    orgFlagIsActive(event.organization, 'enable_select_all_timeslots') &&
    event.select_all_timeslots_enabled

  const isGroupInvite =
    initialQueryParams.is_group_invite?.toLowerCase() === 'true'

  // groups should only have one timeslot
  const maybeTimeslotForGroup =
    availableTimeslots.length === 1 ? availableTimeslots[0] : null
  const isGroupAndAlreadyJoined = !!(
    isGroup &&
    maybeTimeslotForGroup &&
    storedSignedUpTimeslots?.includes(maybeTimeslotForGroup.id)
  )

  const handleSignupButtonsInViewChange = (inView) =>
    setSignupButtonsInView(inView)

  const handleChange = ({name, value}) => {
    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleActLaterClick = (e) => {
    e && e.preventDefault()
    setExpandActLater(true)
    setShifts([null])
  }

  const handleShiftsChange = (shifts) => setShifts(shifts)

  const handleCustomChange = (value) => setCustomSignupFieldValue(value)

  const handleGroupCountChange = (value) => {
    value
      ? setNumAdditionalReservations(value - 1)
      : setNumAdditionalReservations(value)
    if (shiftErrors) {
      setShiftErrors({})
    }
  }

  const handleSubmitSuccess = (
    signupRequest,
    signupResponse,
    updatedCurrentVolunteer,
    signupToShareResponse,
    shouldOpenModal
  ) => {
    onSuccess(signupRequest, signupResponse)
    // SignupForm only includes a subset of fields, so exclude street
    // address fields from the update.
    const currentVolunteerToSet = pick(
      updatedCurrentVolunteer,
      'firstName',
      'lastName',
      'email',
      'phone',
      'zip'
    )
    dispatch(setCurrentVolunteer(currentVolunteerToSet))
    dispatch(
      setEventSignups(
        event.id,
        signupRequest.shifts.map((s) => s.timeslot_id)
      )
    )
    setSignupResponse(signupToShareResponse)
    setErrorFields({})
    setShiftErrors({})
    setModalOpen(shouldOpenModal)
  }

  const handleSubmitError = (errorFields, shifts, shiftErrors) => {
    // If there is only one timeslot, we won't display shiftErrors, so record them in errorFields to display
    if (hasOnlyOneAvailableTimeslot) {
      const shiftAndSignupErrors = Object.assign({}, errorFields, shiftErrors)
      setErrorFields(shiftAndSignupErrors)
    } else {
      setErrorFields(errorFields)
      setShiftErrors(shiftErrors)
    }
    setShifts(shifts)
    setSignupInFlightOrCreated(false)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    // If a tracking param is an empty string or a string composed of spaces it will fail validation.
    // In this step we trim spaces from a tracking param and then set any falsy params (like an empty
    // string) to null.
    for (var param in trackingParams) {
      trackingParams[param] = trackingParams[param]
        ? trackingParams[param].trim()
        : trackingParams[param]
      trackingParams[param] = trackingParams[param]
        ? trackingParams[param]
        : null
    }

    if (
      !validateForm({
        errorFields,
        event,
        setErrorFields,
        shifts,
        customSignupFieldValues,
        isSelfCheckIn,
      })
    ) {
      return
    }
    setSignupInFlightOrCreated(true)
    setSignupCreatedWithinLastFiveSeconds(true)
    setTimeout(() => {
      setSignupCreatedWithinLastFiveSeconds(false)
    }, 5000)
    submitSignupForm({
      customSignupFieldValues,
      event,
      expandActLater,
      initialQueryParams,
      location,
      onError: handleSubmitError,
      onSuccess: handleSubmitSuccess,
      organization,
      precheckedTwoTimeslots,
      selectAllTimeslotsSelected: selectAllOn,
      shifts,
      trackingParams,
      values,
      numAdditionalReservations,
      filterParams,
      eventSuggestionContext,
    })
  }

  const precheckedTwoTimeslots = shouldPrecheckTwoTimeslots(
    maybePrioritizedTimeslot,
    organization,
    event
  )

  const formHeaderMessage = determineFormHeaderMessage({
    includeOrgName: !event.current_org_is_owner_or_co_owner,
    orgName: event.organization.name,
    isAdvocacy,
    isPetition,
    isGroup,
    isSelfCheckIn,
  })

  const getErrorFieldsList = () => {
    // custom_fields errors are handled in the CustomFields component
    return Object.keys(omit(errorFields, 'custom_fields')).map(
      (fieldname) => errorFields[fieldname]
    )
  }

  const totalSupporters = event.total_participant_count || 0
  const showPastEventSupporterCount =
    displayEventAsPassed && !!totalSupporters && isRegistrationOnly

  if (
    displayEventAsPassed ||
    displayEventAsFull ||
    (checkInStartTime && new Date(checkInStartTime) > new Date())
  ) {
    if (showPastEventSupporterCount) {
      return (
        <Card>
          <Typography variant="body1">
            <span role="img" aria-label="green check">
              ✅
            </span>{' '}
            <strong>
              <F defaultMessage="We did it!" />
            </strong>
            <div>
              <F
                defaultMessage="{numSupporters} supporters signed up for this action."
                values={{
                  numSupporters: event.total_participant_count.toLocaleString(),
                }}
              />
            </div>
          </Typography>
          <F
            defaultMessage="You can find more opportunities to volunteer on our <FeedLink>event feed</FeedLink>!"
            values={{
              FeedLink: (msg) => (
                <Link
                  to={
                    organization.branding.url ||
                    getOrganizationFeedUrl(organization).tos_url
                  }
                >
                  {msg}
                </Link>
              ),
            }}
          />
        </Card>
      )
    }

    return (
      <EventClosedBox
        event={event}
        checkInStartTime={checkInStartTime}
        isSelfCheckIn={isSelfCheckIn}
        displayEventAsPassed={displayEventAsPassed}
        displayEventAsFull={displayEventAsFull}
      />
    )
  }

  function renderForm() {
    const shouldShowTimeslots = !hasOnlyOneAvailableTimeslot && !isSelfCheckIn
    const shouldShowCustomFields = !isSelfCheckIn

    const shouldShowGroupSignupField =
      orgFlagIsActive(event.organization, 'enable_group_signup') &&
      event.group_signup_enabled &&
      !isGroupInvite &&
      !isSelfCheckIn

    return (
      <>
        <UserSignupFormFields
          errorFields={errorFields}
          identityFields={identityFields}
          onChange={handleChange}
        />
        {shouldShowCustomFields && (
          <CustomFields
            customSignupFieldValues={customSignupFieldValues}
            event={event}
            onChange={handleCustomChange}
            errors={errorFields.custom_fields}
          />
        )}
        {shouldShowGroupSignupField && (
          <>
            <Typography variant="h3">
              <F defaultMessage="Are you signing up a group?" />
            </Typography>
            <Input
              name="group_signup_count"
              type="number"
              label={<F defaultMessage="Group size (optional)" />}
              fluid
              value={undefined}
              onChange={(e) =>
                handleGroupCountChange(maybeStringToMaybeInt(e.target.value))
              }
              hint={
                event.group_signup_size_limit ? (
                  <F
                    defaultMessage="Number of group members, including yourself. Maximum group size is {limit}"
                    values={{limit: event.group_signup_size_limit}}
                  />
                ) : (
                  <F defaultMessage="Number of group members, including yourself" />
                )
              }
              min={1}
              max={event.group_signup_size_limit || undefined}
            />
          </>
        )}
        {shouldShowTimeslots && (
          <Timeslots
            event={event}
            maybePrioritizedTimeslot={maybePrioritizedTimeslot}
            onChange={handleShiftsChange}
            precheckedTwoTimeslots={precheckedTwoTimeslots}
            shifts={shifts}
            shiftErrors={shiftErrors}
            showSelectAll={showSelectAllButton}
            selectAllOn={selectAllOn}
            setSelectAllOn={setSelectAllOn}
          />
        )}
        {isSelfCheckIn && (
          <SelfCheckInTimeslotWrapper>
            <Typography
              variant="body1"
              center
              style={{
                fontWeight: styles.typography.fontWeightBold,
                marginBottom: styles.space.xs,
              }}
            >
              <F defaultMessage="Checking in for timeslot:" />
            </Typography>
            <Typography
              variant="body1"
              center
              style={{fontWeight: styles.typography.fontWeightBold}}
              data-testid="check_in_timeslot"
            >
              {formatTimeslot(selfCheckInTimeslot, event.timezone, locale)}
            </Typography>
          </SelfCheckInTimeslotWrapper>
        )}
        {organization.branding.custom_signup_form_subtext && (
          <Container>
            <CustomSignupSubtext>
              {organization.branding.custom_signup_form_subtext}
            </CustomSignupSubtext>
          </Container>
        )}
        {Object.keys(errorFields).length > 0 && (
          <Container>
            <Message
              type={MessageType.ERROR}
              header={<F defaultMessage="Signup failed." />}
              list={getErrorFieldsList()}
            />
          </Container>
        )}
        <ActionButtons
          event={event}
          expandActLater={expandActLater}
          identityFields={identityFields}
          onActLaterClick={handleActLaterClick}
          onSignupButtonsInViewChange={handleSignupButtonsInViewChange}
          onShiftsChange={handleShiftsChange}
          shiftErrors={shiftErrors}
          shifts={shifts}
          signupCreatedWithinLastFiveSeconds={
            signupCreatedWithinLastFiveSeconds
          }
          signupInFlightOrCreated={signupInFlightOrCreated}
          isGroupAndAlreadyJoined={isGroupAndAlreadyJoined}
          isSelfCheckIn={isSelfCheckIn}
        />
        <PoweredByMobilize />
        <TermsOfService
          hasMultipleButtons={
            shouldShowActNowButton(event, expandActLater) &&
            shouldShowActLaterButton(event, expandActLater)
          }
        />
        <SMSOptIn
          event={event}
          isChecked={values.smsOptIn}
          onChange={handleChange}
          organization={organization}
        />
      </>
    )
  }

  const enableSupporterCount =
    event.is_virtual_flexible && !event.disable_participant_count
  const enableDonationCount =
    (isPromptForDonationEvent(event) || isDonationCampaignEvent(event)) &&
    !event.disable_participant_count

  return (
    <>
      <Form className="signup-form" onSubmit={handleSubmit}>
        <Card header={formHeaderMessage} data-testid="signup_form">
          {enableSupporterCount && <SupporterProgress expanded event={event} />}
          {!enableSupporterCount && enableDonationCount && (
            <DonationProgress expanded event={event} />
          )}
          {renderForm()}
        </Card>
        <Sticky
          currentVolunteer={currentVolunteer}
          event={event}
          expandActLater={expandActLater}
          identityFields={identityFields}
          initialQueryParams={initialQueryParams}
          onActLaterClick={handleActLaterClick}
          onShiftsChange={handleShiftsChange}
          organization={organization}
          participationShortlink={participationShortlink}
          shareParamsFromSignup={shareParamsFromSignup}
          shifts={shifts}
          shouldDisplay={!signupButtonsInView}
          signupCreatedWithinLastFiveSeconds={
            signupCreatedWithinLastFiveSeconds
          }
          signupInFlightOrCreated={signupInFlightOrCreated}
          isGroupAndAlreadyJoined={isGroupAndAlreadyJoined}
          isSelfCheckIn={isSelfCheckIn}
        />
      </Form>
      <PostModals
        currentVolunteer={currentVolunteer}
        event={event}
        expandActLater={expandActLater}
        identityFields={identityFields}
        modalOpen={modalOpen}
        organization={organization}
        setModalOpen={setModalOpen}
        signupResponse={signupResponse}
        trackingParams={trackingParams}
        owningGroups={owningGroups}
        isSelfCheckIn={isSelfCheckIn}
      />
    </>
  )
}
