import LazyLoad from 'react-lazyload'
import {css} from '@emotion/react'
import {remapImageUrlToProxy} from 'util/image'
import styled from '@emotion/styled/macro'

// TODO(mime): the object-fit is probably not necessary since we
// use imgix...

const ImageInner = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${(props) => css`
    object-fit: ${props.resizeFitMode};
  `};
`

const Wrapper = styled.figure`
  margin: 0;
  width: 100%;
  position: relative;
  padding-top: ${(props) => `${props.ratio * 100}%`};
`

const LOAD_OFFSET = 400

export const ASPECT_RATIO = 1.91

// an image that will lock to a given ratio
export default function Image({
  alt,
  maxWidth,
  ratio = 1 / ASPECT_RATIO,
  resizeFitMode = 'cover',
  src,
  srcSet,
  onLoad,
  skipProxyMapping = false,
}) {
  const maxHeight = maxWidth ? maxWidth * ratio : undefined
  const remappedSrc = skipProxyMapping
    ? src
    : remapImageUrlToProxy(src, maxWidth, maxHeight)
  let remappedSrcSet = srcSet
  if (srcSet && !skipProxyMapping) {
    remappedSrcSet = srcSet
      .split(', ')
      .map((url) => {
        // A srcSet looks like:
        // `http://foo.bar/someimage.png, http://foo.bar/someimage.png?size=2 2x`
        const srcUrlParts = url.split(' ')
        const remappedUrl = remapImageUrlToProxy(
          srcUrlParts[0],
          maxWidth,
          maxHeight
        )
        return remappedUrl + (srcUrlParts[1] ? ` ${srcUrlParts[1]}` : '')
      })
      .join(', ')
  }

  return (
    <Wrapper ratio={ratio}>
      <LazyLoad once offset={LOAD_OFFSET} height="100%">
        <ImageInner
          alt={alt || 'image'}
          src={remappedSrc}
          srcSet={remappedSrcSet}
          resizeFitMode={resizeFitMode}
          onLoad={onLoad}
        />
      </LazyLoad>
    </Wrapper>
  )
}
